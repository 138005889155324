const getApiURL = () => {
  return "https://api.rcta.me";
};

let apiURL = process.env.REACT_APP_API_URL ?? getApiURL();

const apiVersion = "/v1";
apiURL += apiVersion;

export const apiURL_config = apiURL + "/configs";

export const apiURL_auth = apiURL + "/auth";
export const apiURL_auth_login = apiURL_auth + "/login";
export const apiURL_register = apiURL_auth + "/register";
export const apiURL_checkout = apiURL + "/checkout";
export const apiURL_checkout_stripe = apiURL_checkout + "/stripe";

export const apiURL_clinics = apiURL + "/clinics";
export const apiURL_clinics_domain = apiURL + "/domain";
export const apiURL_clinics_user = apiURL_clinics + "/user";
export const apiURL_clinics_me = apiURL_clinics + "/me";
export const apiURL_medic_patient = apiURL + "/medic_patient";
export const apiURL_medic_patient_hash = apiURL_medic_patient + "/hash";
export const apiURL_medics = apiURL + "/medics";
export const apiURL_medics_me = apiURL_medics + "/me";
export const apiURL_medics_unassign = apiURL_medics + "/unassign";
export const apiURL_medics_appointment_types =
  apiURL_medics + "/appointment_types";
export const apiURL_medics_interrupted_agenda =
  apiURL_medics + "/interrupted_agenda";
export const apiURL_nonworking_days = apiURL + "/nonworking_days";
export const apiURL_nonworking_days_collisions =
  apiURL_nonworking_days + "/collisions";
export const apiURL_countries = apiURL + "/countries";
export const apiURL_countries_by_code = apiURL + "/countries/by_code";
export const apiURL_posts = apiURL + "/posts";
export const apiURL_posts_merged = apiURL_posts + "/merged";
export const apiURL_identification_types = apiURL + "/identification_types";
export const apiURL_identification_types_country =
  apiURL_identification_types + "/country";
export const apiURL_genders = apiURL + "/genders";
export const apiURL_timezones = apiURL + "/timezones";
export const apiURL_specialties = apiURL + "/specialties";
export const apiURL_medics_prescriptions_profiles =
  apiURL_medics + "/prescriptions_profiles";
export const apiURL_consulting_rooms = apiURL + "/consulting_rooms";
export const apiURL_timetables = apiURL + "/timetables";
export const apiURL_patients = apiURL + "/patients";
export const apiURL_patient_medical_insurances =
  apiURL + "/patient_medical_insurances";
export const apiURL_patient_medical_insurances_selected =
  apiURL + "/patient_medical_insurances_selected";
export const apiURL_patients_me = apiURL_patients + "/me";
export const apiURL_patients_search = apiURL_patients + "/search";
export const apiURL_patients_search_paginated = apiURL_patients + "/searchPaginated";
export const apiURL_messages = apiURL + "/messages";
export const apiURL_messages_image = apiURL_messages + "/image";
export const apiURL_appointments = apiURL + "/appointments";
export const apiURL_appointments_block = apiURL_appointments + "/block";
export const apiURL_appointments_status = apiURL_appointments + "/status";
export const apiURL_appointments_me = apiURL_appointments + "/me";
export const apiURL_appointments_next = apiURL_appointments + "/next";
export const apiURL_appointments_nexts = apiURL_appointments + "/nexts";
export const apiURL_appointments_types = apiURL_appointments + "/types";
export const apiURL_appointments_videocall_token =
  apiURL_appointments + "/videocall_token";

// Users
export const apiURL_users = apiURL + "/users";
export const apiURL_user_me = apiURL_users + "/me";
export const apiURL_user_cellphone = apiURL_users + "/cellphone";
export const apiURL_user_update_cuit = apiURL_users + "/me/cuit";
export const apiURL_password_request = apiURL_auth + "/password_request";
export const apiURL_password_request_api =
  apiURL_auth + "/password_request_api";
export const apiURL_user_password = apiURL_users + "/password";
export const apiURL_profile_image = apiURL_users + "/profile_image";
export const apiURL_user_SISA_validations = apiURL_users + "/validate_sisa/";
export const apiURL_cellphone = apiURL + "/cellphone";
export const apiURL_cellphone_request_validation =
  apiURL_cellphone + "/request_validation";
export const apiURL_cellphone_validation = apiURL_cellphone + "/validation";

export const apiURL_medical_insurances = apiURL + "/medical_insurances";
export const apiURL_medical_insurances_none = apiURL + "/medical_insurances_none";

export const apiURL_clinic_patients = apiURL + "/clinic_patients";
export const apiURL_external_validator = apiURL_patients + "/external/validator";

export const apiURL_medicines = apiURL + "/medicines";
export const apiURL_medicines_search = apiURL_medicines + "/search";

export const apiURL_medicines_public = apiURL + "/medicines/public";
export const apiURL_medicines_search_public =
  apiURL_medicines_public + "/search";

//Medical prescriptions request
export const apiURL_medical_prescriptions_request =
  apiURL + "/medical_prescriptions_request";

//Medic code
export const apiURL_medic_code = apiURL_medics + "/code";

//Patient by medic code
export const apiURL_patient_code = apiURL_patients + "/code";

// RCTA APP
// RCTA APP
// RCTA APP
// RCTA APP
// RCTA APP
export const apiURL_user_professional_prefixes =
  apiURL_users + "/professional_prefixes";

export const apiURL_user_check_payment_data =
  apiURL_users + "/check_payment_data";

export const apiURL_professional_registration_institutes =
  apiURL + "/professional_registration_institutes";

export const apiURL_professional_registration_types =
  apiURL + "/professional_registration_types";

export const apiURL_consultations = apiURL + "/consultations";

export const apiURL_consultations_status = apiURL_consultations + "status";

export const apiURL_appointments_check_config =
  apiURL_appointments + "/check_config";

export const apiURL_movements = apiURL + "/movements";

export const apiURL_movements_summary = apiURL_movements + "/summary";

export const apiURL_movements_me = apiURL_movements + "/me";

export const apiURL_credit_cards = apiURL + "/credit_cards";

export const apiURL_credit_cards_me = apiURL_credit_cards + "/me";

export const apiURL_vademecum_medicines = apiURL + "/medicines";

export const apiURL_vademecum_medicines_search =
  apiURL_vademecum_medicines + "search?q=";

export const apiURL_plm = apiURL + "/plm";

export const apiURL_plm_medicines = apiURL_plm + "/medicines";

export const apiURL_plm_medicines_search = apiURL_plm_medicines + "search?q=";

export const apiURL_plm_medicines_by_drug_id =
  apiURL_plm_medicines + "/drug_id";

export const apiURL_plm_drugs = apiURL_plm + "/drugs";

export const apiURL_plm_drugs_search = apiURL_plm_drugs + "search?q=";

export const apiURL_medicines_search_icd10 = apiURL_medicines + "icd10?q=";

export const apiURL_medicines_get_icd10 =
  apiURL_plm + "icd10/medicines?medicines_ids=";

export const apiURL_medicines_get_icd10_search = apiURL_plm + "icd10/search?q=";

export const apiURL_medicines_presentations =
  apiURL_medicines + "/presentations";

export const apiURL_indications = apiURL + "/indications";

export const apiURL_indications_by_consultation =
  apiURL_indications + "/consultation";

export const apiURL_certificates = apiURL + "/medical_certificates";

export const apiURL_certificates_me = apiURL_certificates + "/me";

export const apiURL_search_medical_certificates = apiURL + '/search_medical_certificates';

export const apiURL_prescriptions_requests =
  apiURL + "/medical_prescriptions_request";

export const apiURL_prescriptions_request_status =
  apiURL_prescriptions_requests + "/status";

export const apiURL_prescriptions_requests_me =
  apiURL_prescriptions_requests + "/me";

export const apiURL_qr = apiURL + "/qr";

export const apiURL_prescriptions = apiURL + "/medical_prescriptions";

export const apiURL_prescriptions_me = apiURL_prescriptions + "/me";

export const apiURL_prescriptions_test_profile =
  apiURL_prescriptions + "/test_profile";

export const apiURL_prescriptions_config = apiURL_prescriptions + "/config";

export const apiURL_prescriptions_diagnostics =
  apiURL_prescriptions + "/diagnostics/";

export const apiURL_search_medical_prescriptions = apiURL + '/search_medical_prescriptions';

export const apiURL_medical_orders = apiURL + "/medical_orders";

export const apiURL_medical_orders_send_email =
  apiURL_medical_orders + "send_email";

export const apiURL_medical_orders_by_consultation =
  apiURL_medical_orders + "/consultation";

export const apiURL_medical_orders_templates =
  apiURL_medical_orders + "/templates";

export const apiURL_medical_certificates = apiURL + "/medical_certificates";

export const apiURL_medical_certificates_send_email =
  apiURL_medical_certificates + "send_email";

export const apiURL_medical_certificates_by_consultation =
  apiURL_medical_certificates + "/consultation";

export const apiURL_medical_certificates_templates =
  apiURL_medical_certificates + "/templates";

export const apiURL_consultations_complete =
  apiURL_consultations + "/completed";

export const apiURL_videocall = apiURL + "/videocall";

export const apiURL_videocall_channel = apiURL_videocall + "/channel";

export const apiURL_videocall_reject = apiURL_videocall + "/reject";

export const apiURL_videocall_events = apiURL_videocall + "/events";

export const apiURL_consultations_me = apiURL_consultations + "/me";

export const apiURL_parent_roles = apiURL + "/parent_roles";

export const apiURL_faqs = apiURL + "/faqs";

export const apiURL_faqs_categories = apiURL_faqs + "/categories";

export const apiURL_faqs_search = apiURL_faqs + "search?q=";

export const apiURL_identification_types_by_country =
  apiURL_identification_types + "/country";

export const apiURL_schedules = apiURL + "/schedules";

export const apiURL_schedules_me = apiURL + "schedules//me";

export const apiURL_childs = apiURL + "/childs";

export const apiURL_childs_me = apiURL_childs + "/me";

export const apiURL_childs_medics = apiURL_medics + "/childs";

export const apiURL_medics_search = apiURL_medics + "search?q=";

export const apiURL_medics_search_by_specialty =
  apiURL_medics + "search?specialty_id=";

export const apiURL_medics_assign = apiURL_medics + "/assign";

export const apiURL_signatures = apiURL + "/signatures";

export const apiURL_signatures_me = apiURL_signatures + "/me";

export const apiURL_signatures_image_base64 =
  apiURL_signatures + "/image_base64";

export const apiURL_stamps = apiURL + "/stamps";

export const apiURL_stamps_me = apiURL_stamps + "/me";

export const apiURL_consultations_prices = apiURL + "/consultations_prices";

export const apiURL_consultations_prices_final =
  apiURL + "Consultations_prices/calculate_final";

export const apiURL_consultations_prices_me =
  apiURL_consultations_prices + "/me";

export const apiURL_bank_data = apiURL + "/bank_data";

export const apiURL_bank_data_medic = apiURL_bank_data + "/medic";

export const apiURL_bank_data_me = apiURL_bank_data + "me";

export const apiURL_bank_data_account_types =
  apiURL_bank_data + "account_types";

export const apiURL_stats = apiURL_users + "/stats";

export const apiURL_stats_me = apiURL_stats + "/me";

export const apiURL_services = apiURL + "/services";

export const apiURL_services_me = apiURL_services + "/me";

export const apiURL_medical_practices = apiURL + "/medical_practices";

export const apiURL_medical_practices_search =
  apiURL_medical_practices + "/search";

export const apiURL_practices_combos = apiURL + "/practices_combos";

export const apiURL_practices_by_combos = apiURL + "/practices_combos_by_id";

export const apiURL_practices_combos_add = apiURL + "/practices_combos_add";
export const apiURL_practices_combos_delete =
  apiURL + "/practices_combos_delete_by_id";
export const apiURL_practices_combos_delete_all =
  apiURL + "/practices_combos_delete_all";

export const apiURL_practices_combos_me = apiURL_practices_combos + "/me";

export const apiURL_user_profile_image = apiURL_users + "/profile_image";

export const apiURL_user_profile_image_base64 =
  apiURL_users + "/profile_image_base64";

export const apiURL_child_profile_image_base64 =
  apiURL_childs + "/profile_image_base64";

export const apiURL_message_image_base64 = apiURL_messages + "/image_base64";

export const apiURL_auth_resend_email_verification =
  apiURL_auth + "/resend_email_verification";

export const apiURL_consultations_init_checkout =
  apiURL + "/consultations/init_checkout";

export const apiURL_user_privacy = apiURL_medics + "/privacy";

export const apiURL_user_prevent_reprint_prescriptions =
  apiURL_medics + "/prevent_reprint_prescriptions";

export const apiURL_mp = apiURL + "/mercadopago";

export const apiURL_mp_check_authorization = apiURL_mp + "/check_authorization";

export const apiURL_mp_authorize = apiURL_mp + "/authorize";

export const apiURL_payment_methods = apiURL + "/payment_methods";

export const apiURL_prescription_profiles = apiURL + "/prescription_profiles";

export const apiURL_prescription_profiles_me =
  apiURL_prescription_profiles + "/me";

export const apiURL_prescription_profile_logo_image_base64 =
  apiURL_prescription_profiles + "/logo_image_base64";

export const apiURL_identification_image_base64 =
  apiURL_users + "/identification_image_base64";

export const apiURL_prescription_profile_logo_image =
  apiURL_prescription_profiles + "/logo_image";

export const apiURL_prescription_profile_signature_image_base64 =
  apiURL_prescription_profiles + "/signature_image_base64";

export const apiURL_prescription_profile_signature_image =
  apiURL_prescription_profiles + "/signature_image";

export const apiURL_prescription_profiles_by_slug =
  apiURL_prescription_profiles + "/byslug";

export const apiURL_medical_licenses = apiURL + "/medical_license";

export const apiURL_medical_licenses_configurations =
  apiURL_medical_licenses + "/configurations";

export const apiURL_permissions_me =
  apiURL_medics + "/permissions/me";

export const apiUrl_hubspot_get_identified_visitor_token =
    apiURL + "/hubspot_identified_visitor_token";

export const apiUrl_user_close_all_sessions =
    apiURL_users + "/close_all_sessions";

export const apiURL_user_SISA_sync = apiURL_users + '/sync_sisa/';

export const apiURL_reset_password = apiURL + '/auth/reset_password';

export const apiURL_reset_pass_validate = apiURL + '/auth/validate_token';

// export const apiURL_update_medical_licenses_image =
//   apiURL + "/update_medical_license_image";

export const apiURL_patients_by_clinic = apiURL + "/patients/clinic";
