import React, {
    useLayoutEffect,
    useState,
    useEffect,
} from 'react';
import locales_es from '../../../locales/es';
import UserInfo from '../../../components/ConsultDetail/UserInfo';
import APIService from '../../../modules/apiService';
import Helpers from '../../../modules/helpers';
import {useHistory} from 'react-router-dom';
import {showModalInfo} from '../../../modules/ViewHelpers';
import PrescriptionsList from '../../../components/PrescriptionsList';
import { set } from 'react-native-reanimated';

let nativeEventKeySearch = '';

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
   
    useEffect(() => {
       const handler = setTimeout(() => {
         setDebouncedValue(value);
       }, delay);
   
       return () => {
         clearTimeout(handler);
       };
    }, [value, delay]);
   
    return debouncedValue;
};

interface SearchPatientPrescriptionsProps {
    keySearch?: string;
}

const useSearchPatientPrescriptions = (props: SearchPatientPrescriptionsProps) => {
    const history = useHistory();
    const api = new APIService();
    const helpers = new Helpers();
    const params: any = history.location.state;
    const items = props && props.items ? props.items : params && params.items ? params.items : null;
    const keyItemsToSearch = props && props.keyItemsToSearch ? props.keyItemsToSearch : params && params.keyItemsToSearch ? params.keyItemsToSearch : null;
    const onPressItem = props && props.onPressItem ? props.onPressItem : params && params.onPressItem ? params.onPressItem : () => {};
    const pressGoBack = props && props.onGoBack ? props.onGoBack : params && params.onGoBack ? params.onGoBack : () => {};
    const [keySearch, setKeySearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [page,setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total,setTotal] = useState(0);
    const debouncedSearchTerm = useDebounce(keySearch, 500);

    useEffect(() => {
        nativeEventKeySearch = props.keySearch;
        if (props.keySearch?.length > 0) {

            setKeySearch(nativeEventKeySearch);
            setHasMore(true);
        }
        
            setHasMore(true);
            setSearchResults([])
            setPage(1)
            setLoadingMore(false);
        
        
    }, [props.keySearch]);

    const loadMore = ()=>{
        if (loadingMore|| !hasMore) return;
        const objData = {
        q: debouncedSearchTerm,
        };
        setLoadingMore(true);
        api
        .getSearchPrescriptions(objData, page)
        .then((res: any) => {
            setLoadingMore(false)
            if (res.data.current_page === res.data.last_page) {
                setHasMore(false);
            }
            setSearchResults(currentSearchResults => currentSearchResults ? currentSearchResults.concat(res.data.data) : res.data.data);
            setTotal(res.data.total);
        })
        .catch((err: any) => {
            setLoadingMore(false);
            showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err),
            );
        });
    }

    useEffect(() => {
        if (debouncedSearchTerm) {
            setHasMore(true);
            setPage(1)
            setSearchResults([]);
            loadMore();
        }
     }, [debouncedSearchTerm]);

     const onLoadMore = () => {
        if (!loadingMore) {
            setPage(page+1);
            loadMore(); 
        }
     }

    return {
        searchResults,
        onLoadMore,
        total,
        hasMore,
        loadingMore

    };
}

export default useSearchPatientPrescriptions;