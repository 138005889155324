import React, { useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  ImageSourcePropType,
} from "react-native";
import { width } from "../../configs/Const";

interface Action {
  text: string;
  icon: ImageSourcePropType;
  name: string;
  onPress: () => void;
}

interface FloatingButtonRctaProps {
  actions: Action[];
}

const FloatingButtonRcta: React.FC<FloatingButtonRctaProps> = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleActionPress = (action: Action) => {
    console.log(`Pressed ${action.name}`);
    action.onPress();
    setIsOpen(false);
  };

  return (
    <View style={styles.container}>
      {}
      {isOpen && (
        <View style={styles.actionContainer}>
          {actions.map((action, index) => (
            <TouchableOpacity
              key={index}
              style={styles.actionButton}
              onPress={() => handleActionPress(action)}
            >
              <Image source={action.icon} style={styles.actionIcon} />
              <Text style={styles.actionText}>{action.text}</Text>
            </TouchableOpacity>
          ))}
        </View>
      )}

      {}
      <TouchableOpacity
        style={{ ...styles.floatingButton, width: isOpen ? 35 : 100 }}
        onPress={toggleMenu}
      >
        <Text
          center
          style={{
            ...styles.floatingButtonText,
            fontSize: isOpen ? 25 : 18,
            fontWeight: isOpen ? "bold" : "normal",
          }}
        >
          {isOpen ? "-" : "+ Nuevo"} {}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "fixed",
    bottom: 41,
    right: 30,
    alignItems: "flex-end",
  },
  floatingButton: {
    height: 35,
    backgroundColor: "#40cfce",
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    position: "fixed",
    bottom: 20,
    right: 38,
  },
  floatingButtonText: {
    color: "#fff",
    padding: 5,
    paddingTop: 5,
    paddingBottom: 8,
    paddingLeft: 5,
    paddingRight: 0,
    textAlign: "center",
  },
  actionContainer: {
    marginBottom: 16,
    alignItems: "flex-end",
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: 6,
    backgroundColor: "#34c8c8",
    padding: 5,
    borderRadius: 31,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: 200,
  },
  actionIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  actionText: {
    color: "#fff",
    fontSize: 18,
  },
});

export default FloatingButtonRcta;
