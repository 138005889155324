import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Modal,
  Image,
  ActivityIndicator,
  View,
} from "react-native";
import Text from "../../../components/Text";
import HeaderButton from "../../../components/HeaderButton";
import Theme from "../../../style/Theme";
import scale from "../../../utils/scale";
import InputApp from "../../../components/InputApp";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import PrefixSelector from "../../../components/PrefixSelector";
import ModalSelect from "../../../components/ModalSelect";
import locales_es from "../../../locales/es";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { useTheme } from "../../../configs/Theme";
import TextInput from "../../../components/TextInput";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import iconNext from "../../../images/ic_next.png";
import { SOURCE_ICON } from "../../../images";
import Stepper from "./stepper";
import useBasicInformationPage from "../shared/useBasicInformationPage";
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import {Colors} from '../../../configs';
import validateCuitByCountry from "../../../utils/validation/cuit";


interface BasicInformationProps {}

const BasicInformationDesktop = (props: BasicInformationProps) => {
  const { theme } = useTheme();
  const {
    profileMode,
    profileImage,
    uploadProfileImage,
    setCropping,
    professionalPrefixId,
    professionalPrefixes,
    onPressPrefixSelectorItem,
    errors,
    identificationType,
    identification,
    identificationRef,
    onBlur,
    showNonEditableInfo,
    openIdentificationTypesMenuOption,
    locked,
    setIdentification,
    nameRef,
    name,
    setName,
    setLastname,
    lastname,
    lastnameRef,
    setIdTributario,
    idTributario,
    idTributarioRef,
    onSubmit,
    visibleIdentificationTypesMenuOption,
    closeIdentificationTypesMenuOption,
    setIdentificationType,
    identificationTypes,
    cropping,
    enableButton,
    minDocLength,
    maxDocLength,
    countryId,
    showModalSISAInfo,
    SISAVerification,
    setMinDocLength,
    setMaxDocLength,
    setSISAVerification,
    handleSISAVerification,
    handleValidatorInputsTypes,
    errorMessages
  } = useBasicInformationPage();

  const [validationData, setValidationData] = useState({
    validateCuit: false,
    requestCuit: false,
    requestIdTributary: false,
  });

  useEffect(() => {
    const fetchValidationData = async () => {
      try {
        const data = await validateCuitByCountry();
        setValidationData(data);
      } catch (error) {
        console.error('Error fetching validation data:', error);
      }
    };

    fetchValidationData();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {!profileMode && <Stepper />}
        <View style={styles.headerWrapper}>
          <Text size={38} lineHeight={36} bold>
            {locales_es.basicInformation_page.title}
          </Text>
          <Text
            size={18}
            lineHeight={18}
            marginTop={8}
            style={{ maxWidth: "100%" }}
          >
            {locales_es.basicInformation_page.subtitle}
          </Text>
        </View>
        <View style={styles.pageContent}>
          <View style={styles.backgroundImageWrapper}>
            <Image source={SOURCE_ICON.rx} style={styles.backgroundImage} />
          </View>
          <View style={styles.formContainer}>
            <ImgLoadAndCrop
              avatarStyles={styles.avatarStyles}
              containerStyles={styles.imgContainer}
              image={profileImage}
              inputLabel={"Imagen de perfil"}
              imageId={"profileImage"}
              imageState={"profileImage"}
              inputWrapperCustomClassName="col-12 pl-md-0"
              canvasCustomClassName="profile"
              aspect="1"
              saveCrop={uploadProfileImage}
              deleteCrop={() => console.log('deleteCrop')}
              deletedImage={() => console.log('deleteImage')}
              onCropping={() => setCropping(true)}
              onStopCropping={() => setCropping(false)}
            />
            {!cropping && (
            <View style={styles.form}>
              <View style={styles.prefixField}>
                <PrefixSelector
                  prefixes={professionalPrefixes}
                  professionalPrefixId={professionalPrefixId}
                  onPressItem={onPressPrefixSelectorItem}
                  style={styles.prefixSelector}
                  containerStyle={
                  errors.includes('professionalPrefixId')
                      ? {
                          borderWidth: 1,
                          borderRadius: 10,
                          borderColor: theme.lineErrorColor,
                        }
                      : null
                  }
                  showRequire = {true}
                />
                {
                  errors.includes('professionalPrefixId') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.required}</Text>
                }
              </View>
              <View style={styles.formRow}>

                <View style={styles.flexGrow}>
                  {identificationType ? (
                    <InputApp
                      key="identificationType"
                      title="Tipo de Identificación"
                      marginTop={24}
                      marginBottom={4}
                      value={identificationType.name}
                      editable={false}
                      disabled={locked}
                      onPress={() => {
                        locked
                          ? showNonEditableInfo()
                          : openIdentificationTypesMenuOption();
                      }}
                      borderColor={
                        errors.includes('identificationType')
                                ? theme.lineErrorColor
                                : theme.innearColor
                            }
                      showRequired={true}
                    />
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>

                <View style={styles.flexGrow}>
                  <Text type="H4" bold marginTop={24} marginBottom={4}>
                    {locales_es.identification}
                  </Text>
                  <TextInput
                    onPress={() => {
                      if (locked) {
                        showNonEditableInfo();
                      }
                    }}
                    borderColor={
                    errors.includes('identification')
                        ? theme.lineErrorColor
                        : theme.innearColor
                    }
                    onBlur={() => onBlur("identification")}
                    editable={!locked}
                    disabled={locked}
                    forwardRef={identificationRef}
                    value={identification || ""}
                    placeholder={locales_es.identification}
                    onChangeText={(value:string) => {
                      handleValidatorInputsTypes(value,'number',setIdentification,'identification',minDocLength)
                    }}
                    onBlur={(e:any) => {
                      const {value} = e.target;
                      if(countryId === 1 && identificationType && identificationType.id === 1 && identificationType.name == 'DNI'){
                        handleSISAVerification(value)
                      }
                    }}
                    keyboardType="numeric"
                    returnKeyType="go"
                    onSubmitEditing={() => {
                      nameRef.current.focus();
                    }}
                    showRequired={true}
                    maxLength={maxDocLength}
                  />
                </View>
              </View>
                {/* Texto de SISA */}
              <View style={styles.formRow}>

                <View style={styles.flexGrow}>

                </View>

                <View style={styles.flexGrow}>
                  {
                    errors.includes('identification') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.identification}</Text>
                  }
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 10,
                    }}
                  >

                    {
                      SISAVerification && <Text size={13} lineHeight={16} color={Colors.Info} marginTop={5} marginLeft={5} style={{width:430}}>{
                        locales_es.SISARejected.subtitle
                      }</Text>
                    }

                    {
                      countryId === 1 && identificationType && identificationType.id === 1 && identificationType.name == 'DNI' && (
                        <Text size={13} lineHeight={16} marginTop={0} color='gray' marginLeft={5} marginRight={5}
                          onPress={showModalSISAInfo} style={{...styles.alingButtonSISA, alignSelf: 'center' }}
                        >
                          Veficado en el SISA
                          <ButtonIcon
                            icon={'help'}
                            tintColor={Colors.WhiteGray}
                            style={styles.buttonIcon}
                            onPress={showModalSISAInfo}
                            imgStyle={{width: 30, height: 30}}
                          />
                        </Text>
                      )
                    }

                  </View>
                </View>

              </View>

              {validationData.requestIdTributary && (
              <View style={{...styles.formRowEdited, height: '20%'}} >

                <View style={styles.flexGrow}>

                <InputApp
                  forwardRef={idTributarioRef}
                  title={locales_es.idTributario}
                  placeholder={locales_es.idTributario}
                  value={idTributario}
                  marginTop={24}
                  onChangeText={(value: string) => {
                    handleValidatorInputsTypes(value, 'cuit', setIdTributario, 'idTributario', 11);
                  }}
                  borderColor={
                    errors.includes('idTributario') ? theme.lineErrorColor : theme.innearColor
                  }
                  blurOnSubmit={true}
                  onSubmitEditing={onSubmit}
                  autoCapitalize="words"
                  returnKeyType="go"
                  autoFocus={false}
                  showRequired={validationData.requestCuit}
                  keyboardType="numeric"
                  maxLength={25}
                />


                  {
                    errors.includes('idTributario') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.idTributario}</Text>
                  }

                  { (validationData.validateCuit && !idTributario)&& (
                    <Text size={13} style={{ color: 'red' ,marginLeft: 5}}>
                        {locales_es.configuration_page.required_cuit}
                    </Text>

                  )}

                </View>
                <View style={styles.flexGrow}></View>
              </View>

            )}

              <View style={{...styles.formRowEdited, height: '30%'}}>
                <View style={styles.flexGrow}>
                  <InputApp
                    forwardRef={nameRef}
                    marginTop={24}
                    title={locales_es.fullname}
                    value={name}
                    onChangeText={(value:string) => {handleValidatorInputsTypes(value,'letter',setName,'name',2)}}
                    borderColor={
                      errors.includes('name') ? theme.lineErrorColor : theme.innearColor
                    }
                    placeholder={locales_es.fullname}
                    blurOnSubmit={true}
                    onSubmitEditing={() => {
                      lastnameRef.current.focus();
                    }}
                    autoCapitalize="words"
                    returnKeyType="next"
                    autoFocus={false}
                    showRequired={true}
                    maxLength={50}
                  />
                  {
                    errors.includes('name') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.name}</Text>
                  }
                </View>
                <View style={styles.flexGrow}>
                  <InputApp
                    forwardRef={lastnameRef}
                    title={locales_es.lastname}
                    placeholder={locales_es.lastname}
                    value={lastname}
                    marginTop={24}
                    onChangeText={(value:string) => {handleValidatorInputsTypes(value,'letter',setLastname,'lastname',2)}}
                    borderColor={
                      errors.includes('lastname')
                        ? theme.lineErrorColor
                        : theme.innearColor
                    }
                    // onBlur={() => onBlur("lastname")}
                    blurOnSubmit={true}
                    onSubmitEditing={onSubmit}
                    autoCapitalize="words"
                    returnKeyType="go"
                    autoFocus={false}
                    showRequired={true}
                    maxLength={50}
                  />
                  {
                    errors.includes('lastname') && <Text size={13} lineHeight={16} color='red' marginTop={5} marginLeft={5}>{errorMessages.lastname}</Text>
                  }
                </View>
              </View>

              <View style={styles.cta}>
                <ButtonLinear
                  white
                  title={profileMode ? 'Enviar' : 'Continuar'}
                  children={
                    <Image source={iconNext} style={styles.buttonChildren} />
                  }
                  disabled={enableButton}
                  onPress={onSubmit}
                  style={styles.buttonLinear}
                />
              </View>

              {visibleIdentificationTypesMenuOption ? (
                <Modal
                  visible={true}
                  onRequestClose={closeIdentificationTypesMenuOption}
                  transparent
                  animationType={"fade"}

                >
                  <ModalSelect
                  style={{
                    alignSelf: 'center',
                    width:'50%'
                  }}
                    onPressItem={(item) => {
                      // onChangeInput(item.value, item);
                      setIdentificationType(item);
                      setMinDocLength(item.min);
                      setMaxDocLength(item.max);
                      if(item.id !== 1 && item.name !== 'DNI'){
                        setSISAVerification(false);
                      }
                      setIdentification('');
                    }}
                    choices={identificationTypes}
                    close={closeIdentificationTypesMenuOption}
                  />
                </Modal>
              ) : null}
            </View>)}
          </View>
        </View>
      </View>
    </View>
  );
};

export default BasicInformationDesktop;

const styles = StyleSheet.create({
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  inputApp: {
    marginTop: 24,
  },
  name: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: 32,
  },
  buttonLinear: {
    marginTop: 13,
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
    ...Theme.center,
  },
  buttonIcon: {
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundColor: '#fafafa',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 16,
  },
  //nuevo
  imgContainer: { minWidth: 380, width: "70%", marginTop: 0 },
  avatarStyles: { width: 150, height: 150, marginRight: 16 },
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24 },
  prefixField: { width: 200 },
  prefixSelector: {
    padding: 0,
    marginTop: 0,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    gap: 16,
  },
  formRowEdited: {
    flexDirection: "row",
    justifyContent: "flex-start",

    gap: 16,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1 },
  alingButtonSISA: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
