import * as validator from "validator";

export const validateEmptyFields = (fields: any, optional?: boolean) => {
  const errors = <any>[];
  const stateKeys = Object.keys(fields);
  stateKeys.forEach((key) => {
    if ((
      fields[key] === "" ||
      fields[key] === null ||
      fields[key] === undefined ||
      fields[key] === false
    ) && !optional) {
      errors.push(key);
    }
  });
  return errors;
};

export const isEmptyField = (field: any) => {
  return !field;
};

export const validateEmptyArrays = (array: []) => {
  return array.length;
};

export const validateRepeatedInputs = (
  inputValueOne: any,
  inputValueTwo: any
) => {
  return inputValueOne === inputValueTwo;
};

export type InitForm = {
  setErrors: any;
  errors: any;
  setErrorMessages: any;
  errorMessages: any;
  setEnableButton?: any;
  initValues: any;
};

export const handleValidatorInputsTypes = (
  initForm: InitForm,
  value: any,
  type: string = "",
  setState: any,
  id: string,
  minLength: number = 0,
  optional: boolean = false
) => {
  const {
    setErrors,
    errors,
    setErrorMessages,
    errorMessages,
    setEnableButton,
    initValues,
  } = initForm;

  var regex = null;
  var isValidURL = true;
  var textMessage = "";
  var isValidEmail = true;
  var isValidCurrency = true;

  if (type == "letter") {
    regex =
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\u014c\u014d\u016a\u016b\u016c\u016d\u016e\u016f\u0170\u0171\u0172\u0173\u0174\u0175\u0176\u0177\u0178\u0179\u017a\u017b\u017c\u017d\u017e\u012a\u012b\u012c\u012d\u012e\u012f\u0130\u0131 ']*$/;
  }

  if (type === "number") {
    regex = /^[0-9]*$/;
  }

  if (type === "double") {
    regex = /^\d+(\.\d{0,2}){0,1}$/;
  }

  if (type === "currency") {
    var options = {
      thousands_separator: '',
      decimal_separator: '.',
      digits_after_decimal: [1,2]
    }
    isValidCurrency = validator.default.isCurrency(value, options);
    textMessage = "El Campo solo puede contener números";
  }

  if (type === "email") {
    isValidEmail = validator.default.isEmail(value, {
      blacklisted_chars: "+",
    });
    textMessage = "El Campo debe ser un email válido";
  }

  if (type === "link") {
    isValidEmail = validator.default.isURL(value);
    textMessage = "El Campo debe ser un link válido";
  }

  if (type === "password") {
    regex = /^(?=.*?[A-Z])(?=.*?[a-zñÑ])(?=.*?[0-9])(?=.*?[\W_]).{8,}$/;
  }

  var allErrors = errors.filter((item: any) => item !== id);
  setErrors(errors.filter((item: any) => item !== id));

  if (
    value.length > 0 &&
    value.length >= minLength &&
    value.trim().length > 0
  ) {
    if (regex) {
      if (!regex.test(value)) {
        setState(value);
        setErrors([...errors, id]);
        allErrors.push(id);
        if (type === "letter") {
          setErrorMessages({
            ...errorMessages,
            [id]: "El Campo solo puede contener letras",
          });
        }
        if (type === "number") {
          setErrorMessages({
            ...errorMessages,
            [id]: "El Campo solo puede contener números",
          });
        }
        if (type === "double") {
          setErrorMessages({
            ...errorMessages,
            [id]: "El Campo solo puede contener números",
          });
        }
        if (type === "password") {
          setErrorMessages({
            ...errorMessages,
            [id]: "La contraseña no cumple con los requisitos mínimos",
          });
        }
        if (setEnableButton) {
          setEnableButton(handleValidateEmptyFields(allErrors, initValues, optional));
        }
        return;
      }
    } else if (!isValidEmail || !isValidURL || !isValidCurrency) {
      setState(value);
      setErrors([...errors, id]);
      setErrorMessages({
        ...errorMessages,
        [id]: textMessage,
      });
    }
    setState(value);
    if (setEnableButton) {
      setEnableButton(handleValidateEmptyFields(allErrors, initValues, optional));
    }
    return;
  } else if (
    value.length > 0 &&
    value.length < minLength &&
    value.trim().length > 0
  ) {
    if (regex) {
      if (!regex.test(value)) {
        setState(value);
        setErrors([...errors, id]);
        allErrors.push(id);
        if (type === "letter") {
          setErrorMessages({
            ...errorMessages,
            [id]:
              "El Campo solo puede contener letras y debe contener al menos " +
              minLength +
              " caracteres",
          });
        }
        if (type === "number") {
          setErrorMessages({
            ...errorMessages,
            [id]:
              "El Campo solo puede contener números y debe contener al menos " +
              minLength +
              " caracteres",
          });
        }
        if (type === "email") {
          setErrorMessages({
            ...errorMessages,
            [id]:
              "El Campo debe ser un email válido y debe contener al menos " +
              minLength +
              " caracteres",
          });
        }
        if (type === "password") {
          setErrorMessages({
            ...errorMessages,
            [id]: "La contraseña no cumple con los requisitos mínimos",
          });
        }
        if (setEnableButton) {
          setEnableButton(handleValidateEmptyFields(allErrors, initValues, optional));
        }
        return;
      }
    }
    setState(value);
    setErrors([...errors, id]);
    allErrors.push(id);
    setErrorMessages({
      ...errorMessages,
      [id]: "El Campo debe contener al menos " + minLength + " caracteres",
    });
    if (setEnableButton) {
      setEnableButton(handleValidateEmptyFields(allErrors, initValues, optional));
    }
    return;
  } else if (type === 'boolean') {
    setState(!value);
    if (setEnableButton) {
      initValues[id] = !initValues[id];
      setEnableButton(handleValidateEmptyFields(allErrors, initValues, optional));
    }
    return;
  } else if (type === "boolean") {
    setState(!value);
    if (setEnableButton) {
      initValues[id] = !initValues[id];
      setEnableButton(handleValidateEmptyFields(allErrors, initValues));
    }
    return;
  }

  setState("");
  if (!optional) {
    setErrors([...errors, id]);
    allErrors.push(id);
    setErrorMessages({ ...errorMessages, [id]: "El Campo es obligatorio" });
  } else if (id === 'payLink') {
    const getErrors = validateEmptyFields({ ...initValues, }, false);
    getErrors.push('payLink');
    const errorsValue = errors.filter((err: string) => !getErrors.includes(err));
    allErrors = allErrors.filter((err: string) => !getErrors.includes(err))
    setErrors(errorsValue);
  }
  if (setEnableButton) {
    setEnableButton(handleValidateEmptyFields(allErrors, initValues, optional));
  }
};

export const handleValidateEmptyFields = (errors: any, initValues: any, optional: boolean) => {
  const getErrors = validateEmptyFields({
    ...initValues,
  }, optional);
  if (getErrors.length > 0 || errors.length > 0) {
    return true;
  }
  return false;
};
