import { EVENT_SHOW_MODAL_INFO } from "../../constants/Events";
import { api, helpers } from "../../import";
import { EventRegister } from "../../modules/EventRegister";
import locales_es from '../../locales/es';

// Validator for CUIT required by country
const validateCuitByCountry = async () => {

  const user_response = await api
        .getUserMe()
        .then((res:any) => {
          return res;
        });

  const user_data = user_response.data.user


  let validateCuit = false;
  let requestCuit = false;
  let requestIdTributary = false;

  if (user_data.country_id) {
    const countries = await getCountries();

    const currentCountry = countries?.find((c: any) => c.id === user_data.country_id);
    if (currentCountry) {
      requestCuit = currentCountry.requestCuit || false;
      requestIdTributary = currentCountry.requestIdTributary || false;
      if (requestCuit) {
        if (!user_data.idTributario) {
          validateCuit = true;
        }
      }
    }
  }

  return { validateCuit, requestCuit, requestIdTributary, taxId: user_data.idTributario };
};

const getCountries = async () => {
  return await api
    .getCountries()
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.errorModal.title,
        message: helpers.getErrorMsg(err),
      });
      return [];
    });
};



export default validateCuitByCountry;
