import React, { Component } from "react";
import { View, Text } from "react-native";
import moment from "moment/moment";
import locales_es from "../locales/es";
// import {EventRegister} from 'react-native-event-listeners';
import {
  EVENT_SHOW_MODAL_INFO,
  EVENT_SHOW_MODAL_ACTION,
  EVENT_SHOW_MODAL_WITH_INPUT,
EVENT_SHOW_MODAL_WITH_INPUT_CUIT
} from "../constants/Events";
import { EventRegister } from "./EventRegister";
import { DESKTOP_ROUTES } from "../models/constants";


/*
 * Takes an Integer and returns a String as a "24hs." format.
 * */
const getTwentyFourFormatAsString = (int) => {
  return String(int < 10 ? "0" + int : int);
};

// REACT NATIVE CORE JS PATCH
const jsCoreDateCreator = (dateString) => {
  // regex check ONLY "YYYY-MM-DD HH:MM:SS"
  if (/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.test(dateString)) {
    // dateString *HAS* to be in this format "YYYY-MM-DD HH:MM:SS"
    let dateParam = dateString.split(/[\s-:]/);
    dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString();
    // return new Date(...dateParam);
    return new Date(moment(dateString, "YYYY-MM-DD hh:mm:ss"));
  } else {
    // return new Date(dateString);
    return new Date(moment(dateString));
  }
};

export const showModalInfo = (title, message) => {
  const data = {
    title: title,
    message: message,
  }
  if (message.includes('Por favor inicie')) {
    data.logoutModal = true;
  }
  EventRegister.emit(EVENT_SHOW_MODAL_INFO, data);
};

export const showModalInfoAction = (title, message,actions) => {
  EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
    title: title,
    message: message,
    actions:actions
  });
};

export const showModalAction = (title, message, action, width, cancelAction, cancelText, okText) => {
  EventRegister.emit(EVENT_SHOW_MODAL_ACTION, {
    title: title,
    message: message,
    action: action,
    width,
    cancelAction: cancelAction,
    cancelText, okText
  });
};

export const showModalWithInput = (
  title,
  action,
  placeholder = null || "",
  subTitle = null || "",
  width,
  cancelAction = () => {}
) => {
  EventRegister.emit(EVENT_SHOW_MODAL_WITH_INPUT, {
    title: title,
    action: action,
    placeholder: placeholder,
    subTitle: subTitle,
    width: width,
    cancelAction,
  });
};

export const showModalWithInputCuit = (
  title,
  action,
  cancelaction,
  placeholder = null || "",
  subTitle = null || "",
  width
) => {
  EventRegister.emit(EVENT_SHOW_MODAL_WITH_INPUT_CUIT, {
    title: title,
    action: action,
    cancelaction:cancelaction,
    placeholder: placeholder,
    subTitle: subTitle,
    width: width,
  });
};

export function isDesktopRoute(path) {
  return DESKTOP_ROUTES.map((route) => route.toUpperCase()).includes(
    path.toUpperCase()
  );
}

export function renderAgeText(dateString, hideLabel, hideMonths, hideDays) {
  const today = moment();
  // const birthDate = new Date(dateString);
  // const birthDate = this.jsCoreDateCreator(dateString);
  const birthDate = moment(dateString, "YYYY-MM-DDThh:mm:ssZ");
  // let age = today.getFullYear() - birthDate.getFullYear();
  let years = today.diff(birthDate, "year");
  birthDate.add(years, "years");

  let months = today.diff(birthDate, "months");
  birthDate.add(months, "months");

  let days = today.diff(birthDate, "days");

  return `${!hideLabel ? locales_es.age + ": " : ""}
  ${years ? years : ""} ${years <= 0 && months > 0 ? months : ""}${" "}
  ${
    !hideMonths
      ? years <= 0 && months > 0 && months === 1
        ? locales_es.month
        : years <= 0 && months > 1
        ? locales_es.months
        : ""
      : ""
  }${" "}
  ${!hideDays ? (years <= 0 && days > 0 ? days : "") : ""}${" "}
  ${
    !hideDays
      ? years <= 0 && days > 0 && days === 1
        ? locales_es.day
        : years <= 0 && days > 1
        ? locales_es.days
        : ""
      : ""
  }`;
}

export function renderAge(dateString, onlyAge) {
  const today = moment();
  // const birthDate = new Date(dateString);
  // const birthDate = this.jsCoreDateCreator(dateString);
  const birthDate = moment(dateString, "YYYY-MM-DDThh:mm:ssZ");
  // let age = today.getFullYear() - birthDate.getFullYear();
  let years = today.diff(birthDate, "year");
  birthDate.add(years, "years");

  let months = today.diff(birthDate, "months");
  birthDate.add(months, "months");

  let days = today.diff(birthDate, "days");

  return onlyAge ? (
    <>
      <Text>
        {locales_es.age + ": "}
        {years ? years : ""} {years <= 0 && months > 0 ? months : ""}{" "}
        {years <= 0 && months > 0 && months === 1
          ? locales_es.month
          : years <= 0 && months > 1
          ? locales_es.months
          : ""}{" "}
        {years <= 0 && days > 0 ? days : ""}{" "}
        {years <= 0 && days > 0 && days === 1
          ? locales_es.day
          : years <= 0 && days > 1
          ? locales_es.days
          : ""}
      </Text>
    </>
  ) : years || months ? (
    <>
      <Text>
        {locales_es.age + ": "}
        {years ? years : ""}{" "}
        {years > 1 ? locales_es.years : years > 0 ? locales_es.year : ""}{" "}
        {months > 0 ? months : ""}{" "}
        {months > 0 && months === 1
          ? locales_es.month
          : months > 1
          ? locales_es.months
          : ""}{" "}
        {days > 0 ? days : ""}{" "}
        {days > 0 && days === 1
          ? locales_es.day
          : days > 1
          ? locales_es.days
          : ""}
      </Text>
    </>
  ) : null;
}

/*
 * DATE VIEW PARSING
 * Takes a event date and returns it as a parsed custom RN-TAG-VIEW format output
 * only:string | 'day','month','year'
 * */
export function parseEventDate(
  eventDate,
  shortMode,
  /*string*/ only,
  includeTime,
  utcOn
) {
  if (!eventDate) {
    return;
  }

  // const date = new Date(eventDate);
  const date =
    typeof eventDate === "string" ? jsCoreDateCreator(eventDate) : eventDate;

  let monthMatch = {};

  if (shortMode) {
    monthMatch = {
      Ene: 0,
      Feb: 1,
      Mar: 2,
      Abr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Ago: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dic: 11,
    };
  } else {
    monthMatch = {
      Enero: 0,
      Febrero: 1,
      Marzo: 2,
      Abril: 3,
      Mayo: 4,
      Junio: 5,
      Julio: 6,
      Agosto: 7,
      Septiembre: 8,
      Octubre: 9,
      Noviembre: 10,
      Diciembre: 11,
    };
  }

  let month = utcOn ? date.getUTCMonth() : date.getMonth();

  Object.keys(monthMatch).map((key) => {
    if (month === monthMatch[key]) {
      month = key;
    }
    return key;
  });

  const day = utcOn ? date.getUTCDate() : date.getDate();
  const year = utcOn ? date.getUTCFullYear() : date.getFullYear();

  // oNLY
  if (only) {
    switch (only) {
      case "day":
        return day;
      case "month":
        return month;
      case "year":
        return year;
      default:
        return new Error("Unexpected ");
    }
  }
  // Only

  return (
    <Text>
      {typeof month === "string" && shortMode ? <Text>{month} </Text> : null}
      <Text>{day} </Text>
      {typeof month === "string" && !shortMode ? (
        <Text>de {month} </Text>
      ) : null}
      <Text>{year} </Text>
      {includeTime ? (
        <Text>
          {getTwentyFourFormatAsString(
            utcOn ? date.getUTCHours() : date.getHours()
          )}
          :
          {getTwentyFourFormatAsString(
            utcOn ? date.getUTCMinutes() : date.getMinutes()
          )}
        </Text>
      ) : null}
    </Text>
  );
}
