import React, {memo} from 'react';
import Theme from '../../style/Theme';
import { Colors } from '../../configs';
import Text from '../Text';
import { View, StyleSheet, TouchableOpacity, Image, ViewStyle } from 'react-native';
import CloudAddIcon from '../../assets/img/cloud-add.svg';
import EditIcon from '../../assets/img/edit-2.svg';

interface ClinicTagProps {
  title: string;
  clinicIcon: boolean;
  iconStyle?: any;
  style?: ViewStyle;
  color?: string;
  onPressX?: () => void;
}

const ClinicTag = memo((props: ClinicTagProps) => {
  return (
    <View
      style={[
        styles.container,
        props.onPressX
          ? {minHeight: 30, paddingRight: 22}
          : {minHeight: 24, paddingRight: 5},
        props.style,
      ]}>
      {props.clinicIcon ? <img src={CloudAddIcon} style={props.iconStyle} /> : <img src={EditIcon} style={props.iconStyle} />}
      <Text size={14} lineHeight={14} color={props.color}>
        {props.title}
      </Text>
      {props.onPressX ? (
        <TouchableOpacity
          style={styles.touch}
          onPress={() => props.onPressX && props.onPressX()}>
          <Image
            source={require('../../images/ic_close_16.png')}
            style={styles.icon}
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
});

export default ClinicTag;

const styles = StyleSheet.create({
  container: {
    paddingLeft: 5,
    marginRight: 5,
    paddingVertical: 5,
    backgroundColor: Colors.LightWhite,
    borderRadius: 4,
    ...Theme.flexRow,
    marginBottom: 2,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  touch: {
    width: 16,
    height: 16,
    position: 'absolute',
    right: 4,
  },
  icon: {
    width: 16,
    height: 16,
  },
});
