import React, {useCallback, useState} from 'react';
import {View, StyleSheet, Image} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import {Colors, Routes} from '../../configs';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import {useHistory} from "react-router-dom";
import Container from '../../components/Layout/Container';
import InputApp from '../../components/InputApp';
import Content from '../../components/Layout/Content';
import validationEmail from '../../utils/validation/email';

import {api, helpers} from '../../import';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import FullScreenLoading from '../../components/fullscreenLoading';
import {isEmptyField} from '../../utils/validation/forms';
import {useTheme} from '../../configs/Theme';
import {ConsultsType} from '../../configs/Const';
import {FAKE_EMAIL_REGEX, DYNAMIC_TEXT_REGEX} from '../../models/constants';
import imgSentMessage from '../../images/img_sent_message.png';
import imgAccept from '../../images/ic_accept.png';

interface SendDocumentsProps {}

const SendDocuments = (props: SendDocumentsProps) => {
  const mockedEmail = {
    value: '',
  };
  const {theme} = useTheme();
  const history = useHistory();
  const params = history.location.state;
  const [emails, setEmails] = useState(
    params && params.emails
      ? params.emails.map(email =>
          !FAKE_EMAIL_REGEX.test(email.value) ? email : mockedEmail,
        )
      : [mockedEmail],
  );
  const [documents] = useState(params ? params.documents : null);
  const [prescriptionId] = useState(params ? params.prescriptionId : null);
  const [selectionType, setSelectionType] = useState(
    params ? params.selectionType : null,
  );
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const onSend = () => {
    const errors = [];
    emails.map((email, index) => {
      if (isEmptyField(email.value) || !validationEmail(email.value)) {
        errors.push(index);
      }
    });

    if (errors.length) {
      setErrors(errors);
      showModalInfo(locales_es.infoModal.title, locales_es.infoModal.checkData);
    } else {
      setLoading(true);
      const parsedEmails = emails.map(email => email.value);

      if (selectionType === ConsultsType.Certificate) {
        api
          .postCertificateEmail(documents, {emails: parsedEmails})
          .then((res: any) => {
            setLoading(false);
            history.push(`${Routes.SentDocumentSuccessful}`);
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err),
            );
            setLoading(false);
          });
      } else if (selectionType === ConsultsType.QRAddPatient) {
        api
          .postQREmail({emails: parsedEmails})
          .then((res: any) => {
            setLoading(false);
            history.replace(Routes.SentDocumentSuccessful);
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err),
            );
            setLoading(false);
          });
      } else if (selectionType === ConsultsType.SpetialPrescription) {
        api
          .postSpetialPrescription({prescriptionId, emails: parsedEmails})
          .then((res: any) => {
            setLoading(false);
            history.replace(Routes.SentDocumentSuccessful);
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err),
            );
            setLoading(false);
          });
      } else {
        api
          .postPrescriptionEmail(documents, {emails: parsedEmails})
          .then((res: any) => {
            setLoading(false);
            history.push(`${Routes.SentDocumentSuccessful}`);
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err),
            );
            setLoading(false);
          });
      }
    }
  };

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onAddEmail = () => {
    setEmails(prevState => [...prevState, mockedEmail]);
  };

  const onRemoveEmail = index => {
    const _emails = JSON.parse(JSON.stringify(emails));
    _emails.splice(index, 1);
    setEmails(_emails);
  };

  const setEmailValue = (index, text) => {
    const _emails = JSON.parse(JSON.stringify(emails));
    _emails[index].value = text;
    setEmails(_emails);
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter(item => item !== inputStr));
  };

  let documentsToSend =
    selectionType !== ConsultsType.SpetialPrescription ? (documents.length > 1
      ? locales_es.prescriptionsToSend
      : locales_es.prescriptionToSend) : (documents.length > 1
        ? locales_es.spetialPrescriptionsToSend
        : locales_es.spetialPrescriptionToSend);

  return (
    <Container style={styles.wrapper}>
      {loading && <FullScreenLoading />}
      <Content
        extraHeight={100}
        extraScrollHeight={100}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.container}>
        <Image
          source={imgSentMessage}
          style={{width: scale(120, true), height: scale(120, true)}}
        />
        {selectionType === ConsultsType.QRAddPatient ? (
          <>
            <Text size={15} lineHeight={24} marginTop={16} center>
              Agregue las direcciones de correo destino que necesite.
            </Text>
            <Text size={15} lineHeight={24} marginTop={16} center>
              Le enviaremos un archivo PDF para imprimir el QR para su
              consultorio.
            </Text>
          </>
        ) : (
          <>
            <Text size={15} lineHeight={24} marginTop={16} center>
              Agregue las direcciones de correo destino que necesite.
            </Text>
            <Text size={15} lineHeight={24} marginTop={16} center>
              O puede ingresar el correo de su asistente para enviarle la
              receta.
            </Text>
            <Text size={15} lineHeight={24} marginTop={16} center bold>
              { documentsToSend.replace(DYNAMIC_TEXT_REGEX, documents.length)}
            </Text> 
          </>
        )}
        <View style={{width: '100%'}}>
          {emails && emails.length
            ? emails.map((email, index) => {
                return (
                  <View key={index}>
                    <InputApp
                      title={'E-Mail'}
                      marginTop={index > 1 ? 0 : 38}
                      value={email.value}
                      icon={
                        <Image
                          source={imgAccept}
                          style={styles.icon}
                        />
                      }
                      isShowIcon={validationEmail(email.value)}
                      onChangeText={text => setEmailValue(index, text)}
                      borderColor={
                        errors.includes(index)
                          ? theme.lineErrorColor
                          : theme.innearColor
                      }
                      onBlur={() => onBlur(index)}
                      autoCapitalize="none"
                      returnKeyType="go"
                    />
                    {index > 0 && (
                      <Text
                        size={15}
                        lineHeight={24}
                        color={Colors.RedNeonFuchsia}
                        style={{
                          marginVertical: 15,
                          paddingHorizontal: 32,
                        }}
                        center
                        onPress={() => onRemoveEmail(index)}>
                        Remover este e-mail
                      </Text>
                    )}
                  </View>
                );
              })
            : null}
        </View>
        {emails && emails.length < 3 && (
          <Text
            size={15}
            lineHeight={24}
            color={Colors.DodgerBlue}
            style={{marginVertical: 15, paddingHorizontal: 32}}
            center
            onPress={onAddEmail}>
            Agregar otro E-mail
          </Text>
        )}
        <ButtonLinear
          white
          title={locales_es.send}
          style={{paddingHorizontal: 32, marginTop: 50}}
          onPress={onSend}
        />
        <Text
          size={15}
          lineHeight={24}
          color={Colors.DodgerBlue}
          style={{marginTop: 15, paddingHorizontal: 32}}
          onPress={onCancel}>
          Cancelar
        </Text>
      </Content>
    </Container>
  );
};

export default SendDocuments;

const styles = StyleSheet.create({
  wrapper: {
    ...Theme.flexOne,
  },
  container: {
    ...Theme.container,
    ...Theme.center,
  },
});
