import React, {useState, useEffect} from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
  Modal,
  Alert,
  Text as NativeText,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import Text from '../../components/Text';
import Constants, {width, ConsultsType} from '../../configs/Const';
import PrescriptionItem from '../../components/PrescriptionItem';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import {useHistory} from 'react-router-dom';
import locales_es from '../../locales/es';
import Metrics from '../../Themes/Metrics';
import Colors from '../../configs/Colors';
import ButtonLinear from '../Buttons/ButtonLinear';
import ButtonBorder from '../Buttons/ButtonBorder';
import {EVENT_RELOAD_PRESCRIPTIONS} from '../../constants/Events';
import {
  PRESCRIPTION_REQUEST_STATUS_CREATED,
  PRESCRIPTION_REQUEST_STATUS_IGNORED,
  PRESCRIPTION_REQUEST_STATUS_REJECTED,
} from '../../models/constants';
import {api, helpers} from '../../import';
import {showModalInfo, showModalAction, showModalWithInput} from '../../modules/ViewHelpers';
import {Routes} from '../../configs';
import {EventRegister} from '../../modules/EventRegister';
import iconNoPrescriptions from '../../images/Consults/img_no_upcoming.png';
import FullscreenLoading from '../fullscreenLoading';

interface PrescriptionsListProps {
  total?: number;
  onLoadMore?: () => void;
  refreshing?: boolean;
  prescriptions: [];
  emptyText: string;
  title: string;
  type: string;
  prescriptionRequestId: string | number | null;
  stylesItem: any;
}

const PrescriptionsList = ({
                             total,
                             onLoadMore,
                             refreshing,
                             prescriptions,
                             emptyText,
                             title,
                             type,
                             prescriptionRequestId,
                             stylesItem
                           }: PrescriptionsListProps) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onPressItem = item => {
    if (type === ConsultsType.PrescriptionRequest) {
      return () => {
        setShowModal(item);
      };
    } else {
      return null;
    }
  };

  const processItem = () => {
    history.push(`/${Routes.AddPrescription}`, {
      prescriptionRequestId: showModal.id,
      patientId: showModal.patient_id,
    });
    onCloseModal();
  };



  const confirmIgnoreItem = () => {
    setLoading(true);
    api
      .putPrescriptionRequestStatus(showModal.id, {
        status: PRESCRIPTION_REQUEST_STATUS_IGNORED,
      })
      .then((res: any) => {
        setLoading(false);
        showModalInfo(locales_es.successModal.title, res.message);
        EventRegister.emit(EVENT_RELOAD_PRESCRIPTIONS);
      })
      .catch((err: any) => {
        setLoading(false);
        helpers.processError(err);
      });
  };

  const confirmRejectItem = (message: string = '') => {
    setLoading(true);
    let body: any = {
      status: PRESCRIPTION_REQUEST_STATUS_REJECTED,
    };
    if(message.trim().length > 0){
      body.message = message;
    }
    api
      .putPrescriptionRequestStatus(showModal.id, body)
      .then((res: any) => {
       setLoading(false);
        showModalInfo(locales_es.successModal.title, res.message);
        EventRegister.emit(EVENT_RELOAD_PRESCRIPTIONS);
        onCloseModal();
      })
      .catch((err: any) => {
        setLoading(false);
        helpers.processError(err);
      });
  };

  useEffect(() => {
    if (prescriptionRequestId && prescriptions && prescriptions.length) {
      prescriptions.map((item: any) => {
        if (Number(item.id) === Number(prescriptionRequestId)) {
          const result = onPressItem(item);
          result();
          prescriptionRequestId = null;
        }
      });
    }
  }, [prescriptions, prescriptionRequestId]);

  const renderItem = (element: any) => {
    return (
      <PrescriptionItem
        stylesItem={{width: '100%'}}
        {...element.item}
        key={element.item.prescriptionId || element.item.id}
        type={type || ConsultsType.Prescription}
        status={element.item.status}
        onPressItem=
          {element.item.status == PRESCRIPTION_REQUEST_STATUS_CREATED ? onPressItem(element.item)
            : element.item.status == PRESCRIPTION_REQUEST_STATUS_REJECTED ? false :
            null}
      />
    );
  };

  const _handleLoadMore = () => {
    onLoadMore && onLoadMore();
  };

  return (
    <View style={styles.container}>
      {loading && <FullscreenLoading /> }
      {prescriptions && prescriptions.length ? (
        <View style={styles.scrollContainer}>
          {prescriptions.length > 0 && (
            <View style={{flex: 1, flexDirection: 'column'}}>
              <FlatList
                showsVerticalScrollIndicator={false}
                data={prescriptions}
                renderItem={renderItem}
                ListFooterComponent={
                  <View
                    style={{
                      height: Metrics.HEIGHT * 0.3,
                      paddingTop: Metrics.HEIGHT * 0.025,
                    }}>
                    {refreshing && <ActivityIndicator/>}
                  </View>
                }
                onEndReached={_handleLoadMore}
                onEndReachedThreshold={0.5}
              />
            </View>
          )}
        </View>
      ) : (
        <View style={{height: '70%', ...Theme.center}}>
          <Image
            source={iconNoPrescriptions}
            style={{width: scale(160, true), height: scale(160, true)}}
          />
          <Text size={15} lineHeight={24} marginTop={scale(56, true)}>
            {emptyText || locales_es.noPrescriptions}
          </Text>
        </View>
      )}

      <Modal
        visible={!!showModal}
        onRequestClose={onCloseModal}
        transparent
        animationType={'fade'}>
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: Metrics.HEIGHT * 0.1,
          }}>
            
          <PrescriptionItem
          stylesItem={{width: stylesItem ? stylesItem.width : '90%'}}
            {...showModal}
            key={
              showModal.prescriptionId
                ? showModal.prescriptionId.toString()
                : showModal.id
            }
            type={type || ConsultsType.Prescription}
            status={showModal.status}
            onPressItem={processItem}
            
          />
          <Text size={16} lineHeight={24}>
            {locales_es.requestedMedicines}:
          </Text>
          <ScrollView style={{height: 200}}>
            {showModal.medicines && showModal.medicines.length
              ? showModal.medicines.map((medicine: any, index: number) => {
                return (
                  <View
                    key={index.toString()}
                    style={{
                      paddingHorizontal: 24,
                      marginVertical: 10,
                      zIndex: 1,
                      
                      
                    }}>
                    <Text
                      blueLight
                      size={15}
                      lineHeight={24}
                      color={Colors.BlueCrayola}
                      medium>
                      <NativeText
                        style={[
                          medicine.no_replacement ? styles.underline : {},
                        ]}>
                        {medicine.is_generic ? '' : medicine.commercial_name}
                      </NativeText>{' '}
                      ({medicine.drug}) {medicine.presentation}
                      {'\n'}
                      {locales_es.quantity}: {medicine.quantity}
                    </Text>
                    <Text
                      size={15}
                      lineHeight={24}
                      color={Colors.GrayBlue}
                      medium>
                      {medicine.comment}
                    </Text>
                  </View>
                );
              })
              : null}
            {showModal.comments && (
              <View
                style={{
                  paddingHorizontal: 24,
                }}>
                <Text size={16} lineHeight={24} semibold>
                  {locales_es.comments}:
                </Text>
                <Text size={15} lineHeight={24} medium>
                  {showModal.comments}
                </Text>
              </View>
            )}
          </ScrollView>
          <View style={{width: '80%'}}>
              <View>
                <ButtonBorder
                style={{width: '50%', alignSelf: 'center'}}
                  title={locales_es.ignore}
                  onPress={() =>{
                    onCloseModal();
                    showModalAction(
                      locales_es.ignorePrescriptionRequestModal.title,
                      locales_es.ignorePrescriptionRequestModal.subtitle,
                      confirmIgnoreItem,
                    )
                  }
                  }
                />
                <ButtonLinear
                style={{width: '50%', alignSelf: 'center'}}
                  colors={[Colors.Tomato, Colors.Tomato]}
                  white={true}
                  title={locales_es.reject}
                  onPress={() =>{
                    onCloseModal();
                    showModalWithInput(
                      locales_es.rejectPrescriptionRequestModal.title,
                      confirmRejectItem,
                      'Quiere agregar un comentario?',
                      locales_es.rejectPrescriptionRequestModal.subtitle,
                      '50%'
                    );
                  }
                  }
                />
                <ButtonLinear
                  style={{paddingHorizontal: 12, marginTop: 30, width: '50%', alignSelf: 'center'}}
                  white
                  title={locales_es.processRequest}
                  onPress={processItem}
                />
              </View>

            <View style={styles.headerBack}>
              <Text
                blueLight
                size={18}
                lineHeight={18}
                marginTop={20}
                center={true}
                color={Colors.BlueCrayola}
                semibold
                onPress={onCloseModal}>
                {locales_es.close}
              </Text>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default PrescriptionsList;

const styles = StyleSheet.create({
  scrollContainer: {
    paddingTop: 40,
    paddingHorizontal: 24,
    flex: 1,
    flexDirection: 'column',
  },
  container: {
    width: '100vw',
    flex: 1,
    flexDirection: 'column',
  },
  headerBack: {
    paddingTop: 10,
  },
});
