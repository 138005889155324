import React, {memo, useCallback} from 'react';
import {View, StyleSheet, Image, TouchableOpacity} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import {TcodeArea} from '../../type/codeArea';
import {useTheme} from '../../configs/Theme';
import {countryFlag} from '../../configs/Data';
import iconArrowDown from '../../images/down@3x.png';

interface ButtonChangeCodeProps {
  codeArea: TcodeArea;
  onPress: () => void;
}

const ButtonChangeCode = memo((props: ButtonChangeCodeProps) => {
  const {theme} = useTheme();
  const {codeArea} = props;
  const onShowModal = useCallback(() => {
    props.onPress && props.onPress();
  }, [props.onPress]);

  return (
    <TouchableOpacity
      style={[
        styles.phoneAreaCode,
        {backgroundColor: theme.searchBox, borderColor: theme.innearColor},
      ]}
      onPress={onShowModal}>
      <Image source={countryFlag[codeArea.code]} style={styles.flag}/>
      <Text
        size={15}
        lineHeight={24}
        marginLeft={8}
        // color={Colors.DarkJungleGreen}
      >
        {codeArea.prefix_cell_phone}
      </Text>
    </TouchableOpacity>
  );
});

export default ButtonChangeCode;

const styles = StyleSheet.create({
  container: {},
  phoneAreaCode: {
    width: 114,
    height: 48,
    borderRadius: 8,
    borderWidth: 1,
    ...Theme.flexRow,
    paddingHorizontal: 16,
  },
  phoneView: {
    ...Theme.flexRow,
    marginTop: 4,
  },
  changePhoneCode: {
    position: 'absolute',
    right: 16,
    alignSelf: 'center',
  },
  flag: {
    width: 32,
    height: 20,
  },
  icon: {
    width: 20,
    height: 20,
  },
});
