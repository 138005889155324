import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PatientsDataPage from "./pages/patientsData";
import locales_es from "./locales/es";
import intlTelInput from "intl-tel-input";
import { PARSE_TEL_INPUT_ONLY_COUNTRIES,USER_STATUS_PENDING,USER_STATUS_REJECTED,USER_STATUS_BANNED, } from "./models/constants";

import "./assets/css/bootstrap/bootstrap.css";
import "./assets/css/icofont.min.css";
import "./assets/css/simple-line-icons.css";
import "./assets/css/jquery.typeahead.css";
import "./assets/css/bootstrap-select.min.css";
import "./assets/css/style.css";
import "./App.css";
import AuthLoading from "./pages/AuthLoading";
import Routes from "./configs/Routes";
import { CountrySelector } from "./pages/CountrySelector";
import PrescriptionRequestPage from "./pages/prescriptionRequest";
import OnBoarding from "./pages/Onboarding";
import Login from "./pages/Login";
import { HomeDesktop } from "./pages/Home";
import Recovery from "./pages/Recovery";
import { EventRegister } from "./modules/EventRegister";
import {
  EVENT_FORBIDDEN,
  EVENT_SHOW_MODAL_INFO,
  EVENT_SHOW_MODAL_INFO_REGISTER,
  EVENT_SHOW_OVERLAY,
  EVENT_SHOW_MODAL_ACTION,
  EVENT_SHOW_MODAL_WITH_INPUT,
  EVENT_SHOW_MODAL_WITH_INPUT_CUIT
} from "./constants/Events";
import RenderMobileOrDesktop from "./components/RenderMobileOrDesktop";
import AppLayout from "./components/AppLayout";
import MyProfile from "./pages/MyProfile";
import ModalInfoRegister from "./components/ModalInfoRegister";
import ClearCachePage from "./pages/ClearCache";
import {
  BasicInformationDesktop,
} from "./pages/BasicInformation";
import ChangePassword from "./pages/ChangePassword";
import { MyPatientsDesktop } from "./pages/MyPatients";
import SearchPatients from "./pages/SearchPatients";
import {
  PatientProfileDesktop,
} from "./pages/PatientProfile";
import AddPatientHub from "./pages/AddPatientHub";
import { AddPatientDesktop } from "./pages/AddPatient";
import Prescriptions from "./pages/Prescriptions";
import {
  AddPrescriptionDesktop,
} from "./pages/AddPrescription";
import SearchMedicines from "./pages/SearchMedicines";
import DocumentVisor from "./pages/DocumentVisor";
import SendDocuments from "./pages/SendDocuments";
import SentDocumentSuccessful from "./pages/SentDocumentSuccessful";
import {
  PatientPrescriptionsDesktop,
} from "./pages/PatientPrescriptions";
import Certificates from "./pages/Certificates";
import {
  AddCertificateDesktop,
} from "./pages/AddCertificate";
import SearchPractices from "./pages/SearchPractices";
import PrescriptionsRequests from "./pages/PrescriptionsRequests";
import { SignUpMobile, SignUpDesktop } from "./pages/SignUp";
import SignUpSuccessful from "./pages/SignUpSuccessful";
import WebviewLoader from "./pages/WebviewLoader";
import DataUpdater from "./pages/DataUpdater";
import SearchClinicPatients from "./pages/SearchClinicPatients";
import PatientMedicalInsuranceHub from "./pages/PatientMedicalInsuranceHub";
import PatientMedicalInsurance from "./pages/PatientMedicallnsurance";
import EntityUpdater from "./pages/EntityUpdater";
import ModalAction from "./components/ModalAction";
import ModalWithInput from "./components/ModalWithInput";
import ModalWithInputCuit from "./components/ModalWithInputCuit";
import About from "./pages/About/About";
import ClinicPatientsData from "./pages/ClinicPatientsData/ClinicPatientsData";

import {
  ConfigurationDesktop,
} from "./pages/Configuration";
import { WorkProfileDesktop } from "./pages/WorkProfile";
import {
  OtherInformationDesktop,
} from "./pages/OtherInformation";
import {
  PaymentInformationDesktop,
} from "./pages/PaymentInformation";
import {
  CreatingYourFirstPrescriptionDesktop,
} from "./pages/CreatingYourFirstPrescription";
import {
  PatientCertificatesDesktop,
} from "./pages/PatientCertificates";
import ModalInfo from "./components/ModalInfo";
import PersonalPrescriptionRequestPage from "./pages/prescriptionRequest/personalPrescriptionRequest";
import { HubspotChatProvider } from "./context/HubspotChatContext";

import { showModalAction, showModalInfo, showModalInfoAction } from './modules/ViewHelpers';
import ResetPassword from "./pages/ResetPassword/ResetPassword";


// FIREBASE
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { auth } from "./import";

const goToHome = async () => {
  document.location.href = '/Home'
}




class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: "",
      modalContent: "",
      modalActions: "",
      modalInfo: null,
      overlay: null,
      modalAction: null,
      modalWithInput: null,
      modalWithInputCuit: null,
    };
  }



  componentDidMount() {
    this.listenerForbidden = EventRegister.addEventListener(
      EVENT_FORBIDDEN,
      (data) => {
        if (!data.status || data.status === 401) {
          auth.logout(true, true);
        } else {
          this.showMessage(data);
        }
      }
    );
    this.listenerModalInfo = EventRegister.addEventListener(
      EVENT_SHOW_MODAL_INFO,
      (data) => {
        this.setState({
          modalInfo: data,
        });
      }
    );
    this.listenerModalAction = EventRegister.addEventListener(
      EVENT_SHOW_MODAL_ACTION,
      (data) => {
        this.setState({
          modalAction: data,
        });
      }
    );
    this.listenerModalWithInput = EventRegister.addEventListener(
      EVENT_SHOW_MODAL_WITH_INPUT,
      (data) => {
        this.setState({
          modalWithInput: data,
        });
      }
    );
    this.listenerModalWithInputCuit = EventRegister.addEventListener(
      EVENT_SHOW_MODAL_WITH_INPUT_CUIT,
      (data) => {
        this.setState({
          modalWithInputCuit: data,
        });
      }
    );
    this.listenerModalInfoRegister = EventRegister.addEventListener(
      EVENT_SHOW_MODAL_INFO_REGISTER,
      (data) => {
        this.setState({
          modalInfoRegister: data,
        });
      }
    );
    this.listenerOverlay = EventRegister.addEventListener(
      EVENT_SHOW_OVERLAY,
      (data) => {
        this.setState({
          overlay: data,
        });
      }
    );
  }

  componentWillUnmount() {
    EventRegister.removeEventListener(this.listenerModalInfo);
    EventRegister.removeEventListener(this.listenerForbidden);
    EventRegister.removeEventListener(this.listenerModalInfoRegister);
    EventRegister.removeEventListener(this.listenerOverlay);
    EventRegister.removeEventListener(this.listenerModalAction);
    EventRegister.removeEventListener(this.listenerModalWithInput);
    EventRegister.removeEventListener(this.listenerModalWithInputCuit);
  }

  /* MODAL Functions */
  showModal(title, content, actions, customStyles, contentCustomClassName) {
    this.setState({ modalTitle: title });
    this.setState({ modalContent: content });
    this.setState({ modalActions: actions });
    this.setState({ customStyles });
    this.setState({ contentCustomClassName });
    const regex = /Failed to fetch/gim;
    const regexNet = /Network Error/gim;
    regex.test(content) || regexNet.test(content)
      ? window.showFailedToFetchModal()
      : this.show();
  }

  show() {
    this.setState({ visible: true }, () => {
      window.$("#modal-main").modal({
        backdrop: 'static'
      }).modal("show");
    });
  }


  hide() {
    this.setState({ visible: false }, () => {
      window.$("#modal-main").modal("hide");
    });
  }




  showMessage = (data:any) => {
    setTimeout(function(){
      //const userData = localStorage.getItem('user_data');
      let title =  locales_es.noPermision.title;
      let subtitle= data.message?data.message:locales_es.noPermision.subtitle;

      showModalInfoAction(

        title,
        subtitle,
        [
          {
            text: locales_es.confirmLogoutModal.accept,
            onPress: goToHome,
            white: true,
          },
        ]
      );
    }, 2000);
  };





  hideMainLoading() {
    setTimeout(() => {
      window.initMain(true);
    }, 1500);
  }

  parseTelInputs(initialCountry) {
    // TODO Improve
    setTimeout(() => {
      const input = document.querySelector(".cellphoneInput");
      intlTelInput(input, {
        // any initialisation options go here
        initialCountry: initialCountry ? initialCountry.toUpperCase() : "AR",
        onlyCountries: PARSE_TEL_INPUT_ONLY_COUNTRIES,
      });
    }, 1500);
  }

  closeModalInfo = () => {
    this.setState({
      modalInfo: null,
    });
  };

  closeModalInfoLogout = () => {
    this.setState({
      modalInfoLogout: null,
    });
  };

  closeModalAction = () => {
    this.setState({
      modalAction: null,
    });
  };

  closeModalWithInput = () => {
    this.setState({
      modalWithInput: null,
    });
  };

  closeModalWithInputCuit = () => {
    this.setState({
      modalWithInputCuit: null,
    });
  };

  render() {
    const classNameError = "border-danger invalid-field"; // TODO REVIEW LATER

    const {
      modalTitle,
      modalContent,
      modalActions,
      customStyles,
      contentCustomClassName,
      overlay,
    } = this.state;

    return (
      <>
        {this.state.modalInfo && (
          <ModalInfo
            {...this.state.modalInfo}
            onPressDefault={() => this.closeModalInfo()}
          />
        )}

        {this.state.modalInfoLogout && (
          <ModalInfo
            {...this.state.modalInfoLogout}
            onPressDefault={() => this.closeModalInfoLogout()}
          />
        )}

        {this.state.modalInfoRegister && (
          <ModalInfoRegister
            {...this.state.modalInfoRegister}
            onPressDefault={() => this.setState({ modalInfoRegister: false })}
          />
        )}

        {this.state.modalAction && (
          <ModalAction
            {...this.state.modalAction}
            onPressDefault={() => this.closeModalAction()}
          />
        )}

        {this.state.modalWithInput && (
          <ModalWithInput
            {...this.state.modalWithInput}
            onPressDefault={() => this.closeModalWithInput()}
          />
        )}

        {this.state.modalWithInputCuit && (
          <ModalWithInputCuit
            {...this.state.modalWithInputCuit}
            onPressDefault={() => this.closeModalWithInputCuit()}
          />
        )}

        <HubspotChatProvider portalId={process.env.REACT_APP_HUBSPOT_TRACKING_CODE}>
          <div id="some-id"></div>
          <Router>
            <noscript>You need to enable JavaScript to run this app.</noscript>
            <AppLayout>
              <>
                <Switch>
                  <Route
                    exact
                    path={`/${Routes.Services}`}
                    render={(props) => (
                      <PrescriptionRequestPage
                        {...props}
                        classNameError={classNameError}
                        parseTelInputs={this.parseTelInputs}
                        navigation={navigator}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.P}/:slug`}
                    render={(props) => (
                      <PersonalPrescriptionRequestPage
                        {...props}
                        classNameError={classNameError}
                        parseTelInputs={this.parseTelInputs}
                        navigation={navigator}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    path="/clear-cache"
                    render={(props) => <ClearCachePage />}
                  />
                  <Route
                    path="/patients/:code"
                    render={(props) => (
                      <PatientsDataPage
                        classNameError={classNameError}
                        parseTelInputs={this.parseTelInputs}
                        identificationOptions={this.state.identificationOptions}
                        medicalInsurancesOptions={
                          this.state.medicalInsurancesOptions
                        }
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    path="/clinicPatients"
                    render={(props) => (
                      <ClinicPatientsData
                        classNameError={classNameError}
                        parseTelInputs={this.parseTelInputs}
                        identificationOptions={this.state.identificationOptions}
                        medicalInsurancesOptions={
                          this.state.medicalInsurancesOptions
                        }
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    path={["/ResetPassword/:token", "/CreatePassword/:token"]}
                    render={(props) => (
                      <ResetPassword
                        classNameError={classNameError}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    path="/entity_updater/:medicToken/:patientToken"
                    render={(props) => (
                      <EntityUpdater
                        classNameError={classNameError}
                        parseTelInputs={this.parseTelInputs}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.CountrySelector}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <CountrySelector
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.OnBoarding}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <OnBoarding
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.Login}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <Login
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.Recovery}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <Recovery
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.Home}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<HomeDesktop {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.MyProfile}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <MyProfile
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.Configuration}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<ConfigurationDesktop {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.BasicInformation}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<BasicInformationDesktop {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.ChangePassword}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <ChangePassword
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.MyPatients}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <MyPatientsDesktop
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    path={`/${Routes.About}`}
                    render={(props) => (
                      <About
                        classNameError={classNameError}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.SearchPatients}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <SearchPatients
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.SearchClinicPatients}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <SearchClinicPatients
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PatientProfile}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<PatientProfileDesktop {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PatientCertificates}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <PatientCertificatesDesktop
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.DataUpdater}`}
                    render={(props) => (
                      <DataUpdater
                        {...props}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PatientCertificates}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <PatientCertificatesDesktop
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.AddPatientHub}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <AddPatientHub
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.AddPatient}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <AddPatientDesktop
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />

                  <Route
                    exact
                    path={`/${Routes.Prescriptions}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <Prescriptions
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.AddPrescription}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<AddPrescriptionDesktop {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.SearchMedicines}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <SearchMedicines
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMa
                            inLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.DocumentVisor}`}
                    render={(props) => (
                      <DocumentVisor
                        {...props}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.SendDocuments}`}
                    render={(props) => (
                      <SendDocuments
                        {...props}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.SentDocumentSuccessful}`}
                    render={(props) => (
                      <SentDocumentSuccessful
                        {...props}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PatientPrescriptions}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <PatientPrescriptionsDesktop
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.Certificates}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <Certificates
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.AddCertificate}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <AddCertificateDesktop
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.SearchPractices}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <SearchPractices
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PrescriptionsRequests}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <PrescriptionsRequests
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={[`/${Routes.SignUp}`,`/:country/${Routes.SignUp}`]}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<SignUpDesktop {...props} />}
                        MobileVersion={<SignUpMobile {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.SignUpSuccessful}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <SignUpSuccessful
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.WorkProfile}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<WorkProfileDesktop {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.OtherInformation}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <OtherInformationDesktop
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PaymentInformation}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={<PaymentInformationDesktop {...props} />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.CreatingYourFirstPrescription}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <CreatingYourFirstPrescriptionDesktop {...props} />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.WebviewLoader}`}
                    render={(props) => (
                      <WebviewLoader
                        {...props}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PatientMedicalInsuranceHub}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <PatientMedicalInsuranceHub
                            {...props}
                            showMainModal={(
                              title,
                              content,
                              actions,
                              customStyles,
                              contentCustomClassName
                            ) =>
                              this.showModal(
                                title,
                                content,
                                actions,
                                customStyles,
                                contentCustomClassName
                              )
                            }
                            hideMainLoading={this.hideMainLoading}
                          />
                        }
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/${Routes.PatientMedicalInsurance}`}
                    render={(props) => (
                      <RenderMobileOrDesktop
                        DesktopVersion={
                          <PatientMedicalInsurance {...props} />
                        }
                      />
                    )}
                  />
                  <Route
                    path="*"
                    render={(props) => (
                      <AuthLoading
                        {...props}
                        showMainModal={(
                          title,
                          content,
                          actions,
                          customStyles,
                          contentCustomClassName
                        ) =>
                          this.showModal(
                            title,
                            content,
                            actions,
                            customStyles,
                            contentCustomClassName
                          )
                        }
                        hideMainLoading={this.hideMainLoading}
                      />
                    )}
                  />
                </Switch>

                <div
                  style={customStyles}
                  className="modal fade"
                  id="modal-main"
                  tabIndex="-1"
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    className={
                      "modal-dialog modal-dialog-centered modal-dialog-scrollable " +
                      contentCustomClassName
                    }
                  >
                    <div className="modal-content">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="icofont-close-line" />
                      </button>

                      {modalTitle && (
                        <div className="modal-header">
                          <h5 className="modal-title">{modalTitle}</h5>
                        </div>
                      )}

                      {modalContent && (
                        <div className="modal-body">{modalContent}</div>
                      )}

                      {modalActions === null ? null : (
                        <div className="modal-footer">
                          <div className="actions">
                            {modalActions && modalActions.length ? (
                              modalActions
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                              >
                                {locales_es.accept}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="modalFailedToFetch"
                  tabIndex="-1"
                  role="dialog"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="icofont-close-line" />
                      </button>

                      <div className="modal-header">
                        <h5 className="modal-title">¿Tienes Internet?</h5>
                      </div>

                      <div className="modal-body">
                        <p>
                          Revisa tu conexión a Internet y vuelve a intentarlo en
                          unos momentos.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-outline-brand"
                          data-dismiss="modal"
                        >
                          {locales_es.accept}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </AppLayout>
          </Router>
        </HubspotChatProvider>

        {overlay ? (
          <div className="rcta-main-overlay">{overlay && overlay.children}</div>
        ) : null}
      </>
    );
  }
}

// FIREBASE
const firebaseConfig = {
    apiKey: "AIzaSyAL-NTDYgPI4hkUBPLod_03CAnsyo716rs",
    authDomain: "rcta-innovamed.firebaseapp.com",
    projectId: "rcta-innovamed",
    storageBucket: "rcta-innovamed.appspot.com",
    messagingSenderId: "168647777275",
    appId: "1:168647777275:web:e198fdde109826a07f495e",
    measurementId: "G-WNC8QKP0F8"
  };

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app)
export default App;
