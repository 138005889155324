import React, { Component } from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import locales_es from "../../locales/es";
import Form from "../../components/form";
import APIService from "../../modules/apiService";
import DateTimeService from "../../modules/DateTimeService";
import Helpers from "../../modules/helpers";
import { withRouter } from "react-router";
// import { withRouter } from "../../modules/withRouter";
import SuccessIcon from "../../images/icon-success-circle-green-tick.png";
import Footer from "../../components/footer";
import ImgLoadAndCrop from "../../components/imgLoadAndCrop";
import Text from "../../components/Text";
import Theme from "../../style/Theme";
import TranslationService from "../../locales";
import GenderItem from "../../components/UpdateProfile/BasicInformation/GenderItem";
import { CommonStyles } from "../../styles/CommonStyles";
import RequireIndicator from "../../components/RequireIndicator";
import ConfigService from "../../modules/configService";

class ClinicPatientsData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeIsValid: false,
      showWelcome: true,
      success: false,
      clinic: {},
      clinicCountryId: "",
      clinicCountryCode: "",
      clinicLogoImage: "",
      name: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      identificationTypeId: "1",
      identification: "",
      birthDate: "",
      selectedDate: "",
      gender: "",
      genderOptions: [],
      identificationOptions: [],
      medicalInsurancesOptions: [],
      medicalInsuranceId: "",
      medicalInsurancePlan: "",
      medicalInsuranceNumber: "",
      medicalInsuranceDniTitular: "",
      medicalInsurance: null,
      medicalInsuranceName: "",
      comment: "",
      errors: [],
      errorMessages: {},
      requestSent: false,
      isIOSCOR: false,
      Cropping: false,
      profileImage: "",
      translations: {},
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateService = new DateTimeService();
    this.translationService = new TranslationService();
    this.configService = new ConfigService();
  }

  componentDidMount() {
    // Oculta Intercom mediante CSS
    if (window && window.document) {
      window.document.body.classList.add("rcta-hide-intercom");
    }
    this.configService
      .getLocalClinicData()
      .then((res) => {
        console.log(res);
        this.setState(
          {
            codeIsValid: true,
            clinic: res,
            clinicCountryId: res.country_id,
          },
          () => {
            this.api
              .getIdentificationTypesByCountryId(this.state.clinicCountryId)
              .then((res) => {
                this.setState(
                  {
                    identificationOptions: res.data,
                  },
                  () => {
                    this.api
                      .getGenders()
                      .then((res) => {
                        this.setState({
                          genderOptions: res.data.map((item) => {
                            item.label = item.name;
                            item.value = item.id;
                            return item;
                          }),
                        });
                        this.api
                          .getMedicalInsurancesByCountryId(
                            this.state.clinicCountryId
                          )
                          .then((res3) => {
                            this.setState(
                              {
                                medicalInsurancesOptions: res3.data.map(
                                  (item) => {
                                    item.label = item.name;
                                    item.value = item.id;
                                    return item;
                                  }
                                ),
                                medicalInsurance: res3.data[0],
                              },
                              () => {
                                this.setState({
                                  gender: this.state.genderOptions[2],
                                });
                                this.props.hideMainLoading();
                                const insuranceNone = res3.data.filter(
                                  (item) => item.is_none
                                )[0];
                                this.setState({
                                  medicalInsuranceId: {
                                    id: insuranceNone.id,
                                    label: insuranceNone.label,
                                    value: insuranceNone.id,
                                    is_none: true,
                                  },
                                });
                              }
                            );
                          })
                          .catch((err) => {
                            this.props.showMainModal(
                              locales_es.errorModal.title,
                              this.helpers.getErrorMsg(err)
                            );
                          });
                      })
                      .catch((err) => {
                        this.props.showMainModal(
                          locales_es.errorModal.title,
                          this.helpers.getErrorMsg(err)
                        );
                      });
                  }
                );
              })
              .catch((err) => {
                this.props.showMainModal(
                  locales_es.errorModal.title,
                  this.helpers.getErrorMsg(err)
                );
              });
          }
        );
      })
      .catch((err) => {
        this.props.hideMainLoading();
        this.props.showMainModal(
          locales_es.errorModal.title,
          this.helpers.getErrorMsg(err)
        );
      });
  }

  clearErrors() {
    this.setState({ errors: [] });
  }

  removeFromErrors(string) {
    let errors = this.state.errors;
    errors = errors.filter((item) => item !== string);
    let { [string]: _, ...errorMessage } = this.state.errorMessages;
    this.setState({
      errors,
      errorMessages: errorMessage,
    });
  }

  validateFormReactive = (state, value) => {
    let isValid = true;
    let errorMessage = "";

    const fieldNames = {
      email: "El email",
      phoneNumber: "El Número de teléfono",
      identification: "La Identificación",
      name: "El Nombre",
      lastname: "El Apellido",
      medicalInsurancePlan: "El plan de seguro médico",
      medicalInsuranceNumber: "El número de seguro médico",
    };

    if (
      (!value || value.trim() === "") &&
      state !== "medicalInsurancePlan" &&
      state !== "medicalInsuranceNumber" &&
      state !== "medicalInsuranceId" &&
      state !== "medicalInsuranceDniTitular" &&
      state !== "email" &&
      state !== "phoneNumber"
    ) {
      errorMessage = `Campo obligatorio`;
      isValid = false;
    } else {
      if (state === "email") {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const emailLength = value.length;
        if (emailLength >= 1) {
          isValid =
            emailRegex.test(value) && emailLength >= 8 && emailLength <= 75;
        }

        if (!isValid) {
          if (!emailRegex.test(value) && emailLength < 8) {
            errorMessage = `${fieldNames[state]} debe contener al menos 8 caracteres`;
          }

          if (!emailRegex.test(value) && emailLength >= 8) {
            errorMessage = `El Campo debe ser un email valido.`;
          }

          if (!emailRegex.test(value) && emailLength > 75) {
            errorMessage = `${fieldNames[state]} debe contener menos de 75 caracteres`;
          }
        }
      }

      if (state === "phoneNumber") {
        const phoneRegex = /^[0-9]+$/;
        const phoneLength = value.length;
        let firstValidationFlag = false;
        if (phoneLength >= 1) {
          isValid =
            phoneRegex.test(value) && phoneLength >= 6 && phoneLength <= 12;
        }

        if (!isValid) {
          if (!phoneRegex.test(value)) {
            errorMessage = `${fieldNames[state]} solo puede contener números`;
            firstValidationFlag = true;
          }

          if (phoneLength < 6) {
            if (firstValidationFlag) {
              errorMessage += " y ";
            } else {
              errorMessage += fieldNames[state].toLowerCase();
            }
            errorMessage += `debe contener al menos 6 caracteres`;
          }
          if (phoneLength > 12) {
            errorMessage = `${fieldNames[state]} debe contener menos de 12 caracteres`;
          }
        }
      }

      if (state === "identification") {
        const idRegex = /^[0-9]+$/;
        const idLength = value.length;
        let identificationTypeId = this.state.identificationTypeId;
        let maxLength = 8;
        let minLength = 6;

        if (
          identificationTypeId == 2 ||
          identificationTypeId == 3 ||
          identificationTypeId == 5
        ) {
          maxLength = 10;
          minLength = 6;
        } else if (identificationTypeId == 4) {
          maxLength = 8;
        } else if (identificationTypeId == 1) {
          minLength = 4;
          maxLength = 8;
        }

        if (!idRegex.test(value)) {
          errorMessage = `${fieldNames[state]} solo puede contener números y debe contener al menos ${minLength} caracteres`;
          isValid = false;
        } else if (idLength < minLength) {
          errorMessage = `${fieldNames[state]} debe contener al menos ${minLength} caracteres`;
          isValid = false;
        } else if (idLength > maxLength) {
          errorMessage = `${fieldNames[state]} debe contener un máximo de ${maxLength} caracteres`;
          isValid = false;
        }
      }

      if (state == "name" || state === "lastname") {
        const nameRegex = /^[a-zA-Z\sáéíóúÁÉÍÓÚüÜñÑ'´`]+$/;
        const nameLength = value.length;
        isValid = nameRegex.test(value) && nameLength >= 2 && nameLength <= 50;
        if (!isValid) {
          if (!nameRegex.test(value) && nameLength >= 2) {
            errorMessage = `${fieldNames[state]} solo puede contener letras`;
          }
          if (nameLength < 2) {
            if (errorMessage.includes("solo puede contener letras")) {
              errorMessage = `${fieldNames[state]} debe contener al menos 2 caracteres y solo puede contener letras`;
            }
            errorMessage = `${fieldNames[state]} debe contener al menos 2 caracteres`;
          }
          if (nameLength > 50) {
            errorMessage = `${fieldNames[state]} debe contener menos de 50 caracteres`;
          }

          if (nameLength == 0) {
            errorMessage = `Campo obligatorio`;
          }
          if (!nameRegex.test(value) && nameLength < 2) {
            errorMessage = `${fieldNames[state]} solo puede contener letras y debe contener al menos 2 caracteres`;
          }
        }
      }
    }

    if (this.state.medicalInsuranceId) {
      const data = value;
      if (
        this.state.medicalInsuranceId.id !== 1 &&
        state == "medicalInsuranceNumber" &&
        data.length == 0 &&
        this.state.medicalInsuranceName.toLocaleLowerCase() !==
          "mpn - medicamentos de primer nivel"
      ) {
        errorMessage = `Campo obligatorio`;
        isValid = false;
      }
    }

    if (!isValid) {
      this.setState((prevState) => ({
        errors: [...prevState.errors, state],
        errorMessages: { ...prevState.errorMessages, [state]: errorMessage },
      }));
    } else {
      this.removeFromErrors(state);
    }
  };

  handleChange = (state) => (ev) => {
    let data = ev.target.value;
    let stateName = state;
    this.setState({ [state]: ev.target.value });
    this.validateFormReactive(stateName, data);
  };

  handleReactSelectChange = (state) => (value) => {
    let data = value;
    let stateName = state;
    this.clearErrors();
    this.setState({ [state]: value });
    if (state == "medicalInsuranceId") {
      this.setState({
        isIOSCOR: value && value.name.toLowerCase().includes("ioscor"),
      });
      this.setState({ medicalInsuranceName: value.name });
    }
    this.validateFormReactive(stateName, data);
  };

  handleDateChange = (state) => (value) => {
    this.setState({ [state]: value });
    const parsedDate = this.dateService.parseDateToAPIString(value);
    this.setState({ birthDate: parsedDate });
  };

  render() {
    const { domain } = this.props;
    const { errors, errorMessages } = this.state;
    const _errors = errors;

    // const classNameError = this.props.classNameError;
    const classNameError = "border-danger invalid-field";
    const inputs = [
      {
        label: locales_es.name,
        placeholder: locales_es.fullName,

        id: 1,
        state: "name",
        value: this.state.name,
        type: "name",
        required: true,
        wrapperCustomClassName: "form-group col-6 col-md-6 float-left pl-md-0",
        customClassName: errors.includes("name") && classNameError,
        error: _errors.includes("name") && classNameError,
        errorMessage: _errors.includes("name") ? errorMessages["name"] : null,
        validate: true,
      },
      {
        label: locales_es.lastname,
        placeholder: locales_es.lastname,

        id: 2,
        state: "lastname",
        value: this.state.lastname,
        type: "lastname",
        required: true,
        wrapperCustomClassName: "form-group col-6 col-md-6 float-left pr-md-0",
        customClassName: _errors.includes("lastname")
          ? classNameError
          : _errors.includes("name")
          ? " mb-4"
          : null,
        error: _errors.includes("lastname") && classNameError,
        errorMessage: _errors.includes("lastname")
          ? errorMessages["lastname"]
          : null,
        validate: true,
      },
      {
        label: locales_es.emailOptional,
        placeholder: locales_es.email,

        id: 3,
        state: "email",
        value: this.state.email,
        maxLength: 75,
        type: "email",
        required: false,
        wrapperCustomClassName: "form-group col-6 col-md-6 float-left pl-md-0",
        customClassName: _errors.includes("email") && classNameError,
        errorMessage: _errors.includes("email") ? errorMessages["email"] : null,
        error: _errors.includes("email") && classNameError,
        validate: true,
      },
      {
        label: locales_es.phoneNumber,
        placeholder:
          locales_es[`phoneNumberExample_${this.state.clinic.country_id}`] ||
          locales_es.phoneNumberExample,

        id: 4,
        state: "phoneNumber",
        value: this.state.phoneNumber,
        type: "text",
        required: false,
        wrapperCustomClassName: "form-group col-6 col-md-6 float-left pr-md-0",
        customClassName:
          "cellphoneInput " +
          (_errors.includes("phoneNumber")
            ? classNameError
            : _errors.includes("email")
            ? " mb-4"
            : null),
        errorMessage: _errors.includes("phoneNumber")
          ? errorMessages["phoneNumber"]
          : null,
        error: _errors.includes("phoneNumber") && classNameError,
        validate: true,
      },
      {
        label: locales_es.birthDate,
        placeholder: locales_es.birthDateExample,
        onBlur: () => this.removeFromErrors("birthDate"),
        id: 5,
        state: "selectedDate",
        value: this.state.selectedDate,
        type: "date",
        required: false,
        wrapperCustomClassName:
          "form-group col-12 col-md-3 float-left pl-md-0 clear",
        customClassName: errors.includes("selectedDate") && classNameError,
        maxDate: new window.Date().getTime(),
        // hideCalendar: true,
      },
      // {
      //   label: locales_es.gender,
      //   onBlur: () => this.removeFromErrors('gender'),
      //   id: 6,
      //   state: 'gender',
      //   value: this.state.gender,
      //   type: 'react-select',
      //   options: this.state.genderOptions,
      //   required: false,
      //   wrapperCustomClassName: 'form-group col-12 col-md-3 float-left pl-md-0 clear',
      //   customClassName: (errors.includes('gender') && classNameError),
      // },
    ];

    const inputsIdentification = [
      {
        label: locales_es.identificationType,
        placeholder: locales_es.identificationType,
        onBlur: () => this.removeFromErrors("identificationTypeId"),
        id: 7,
        state: "identificationTypeId",
        value: this.state.identificationTypeId,
        type: "select",
        required: true,
        options: this.state.identificationOptions,
        wrapperCustomClassName:
          "form-group col-12 col-md-3 float-left pl-md-0 mt-1",
        customClassName:
          errors.includes("identificationTypeId") && classNameError,
      },
      {
        label: locales_es.identification,
        placeholder: locales_es.identification,

        id: 8,
        state: "identification",
        value: this.state.identification,
        type: "text",
        maxLength: 10,
        required: true,
        wrapperCustomClassName:
          "form-group col-12 col-md-3 float-left pl-md-0 clear mt-1",
        customClassName: errors.includes("identification") && classNameError,
        error: errors.includes("identification") && classNameError,
        errorMessage: errors.includes("identification")
          ? errorMessages["identification"]
          : null,
        validate: true,
      },
    ];

    const inputsInsuranceIoscor = [
      {
        label: locales_es.medicalInsurance,
        placeholder: locales_es.medicalInsurance,
        onBlur: () => this.removeFromErrors("medicalInsuranceId"),
        id: 9,
        state: "medicalInsuranceId",
        value: this.state.medicalInsuranceId,
        type: "react-select",
        required: true,
        options: this.state.medicalInsurancesOptions,
        wrapperCustomClassName:
          "form-group col-12 col-md-4 float-left pl-md-0 clear",
        customClassName:
          errors.includes("medicalInsuranceId") && classNameError,
      },
      {
        label: locales_es.medicalInsuranceNumber,
        placeholder: locales_es.medicalInsuranceNumber,
        onBlur: () => this.removeFromErrors("medicalInsuranceNumber"),
        id: 11,
        state: "medicalInsuranceNumber",
        value: this.state.medicalInsuranceNumber,
        required: !this.state.medicalInsuranceId.is_none,
        wrapperCustomClassName:
          "form-group col-12 col-md-4 float-left pl-md-0 clearfix",
        customClassName:
          errors.includes("medicalInsuranceNumber") && classNameError,
        errorMessage: _errors.includes("medicalInsuranceNumber")
          ? errorMessages["medicalInsuranceNumber"]
          : null,
        error: _errors.includes("medicalInsuranceNumber") && classNameError,
      },
      {
        label: locales_es.dniTitular,
        placeholder: locales_es.dniTitular,

        id: 10,
        state: "medicalInsuranceDniTitular",
        value: this.state.medicalInsuranceDniTitular,
        required: false,
        wrapperCustomClassName: "form-group col-12 col-md-4 float-left pl-md-0",
        customClassName:
          errors.includes("medicalInsuranceDniTitular") && classNameError,
      },
    ];

    const inputsInsurance = [
      {
        label: locales_es.medicalInsurance,
        placeholder: locales_es.medicalInsurance,
        onBlur: () => this.removeFromErrors("medicalInsuranceId"),
        id: 9,
        state: "medicalInsuranceId",
        value: this.state.medicalInsuranceId,
        type: "react-select",
        required: true,
        options: this.state.medicalInsurancesOptions,
        wrapperCustomClassName:
          "form-group col-12 col-md-4 float-left pl-md-0 clear",
        customClassName:
          errors.includes("medicalInsuranceId") && classNameError,
      },
      {
        label: locales_es.medicalInsurancePlan,
        placeholder: locales_es.medicalInsurancePlan,

        id: 10,
        state: "medicalInsurancePlan",
        value: this.state.medicalInsurancePlan,
        required: false,
        wrapperCustomClassName: "form-group col-12 col-md-4 float-left pl-md-0",
        customClassName:
          errors.includes("medicalInsurancePlan") && classNameError,
        errorMessage: _errors.includes("medicalInsurancePlan")
          ? errorMessages["medicalInsurancePlan"]
          : null,
        error: _errors.includes("medicalInsurancePlan") && classNameError,
        visible:
          this.state.medicalInsuranceName.toLocaleLowerCase() !==
          "mpn - medicamentos de primer nivel",
      },
      {
        label: locales_es.medicalInsuranceNumber,
        placeholder: locales_es.medicalInsuranceNumber,

        id: 11,
        state: "medicalInsuranceNumber",
        value: this.state.medicalInsuranceNumber,
        required:
          !this.state.medicalInsuranceId.is_none &&
          this.state.medicalInsuranceName.toLocaleLowerCase() !==
            "mpn - medicamentos de primer nivel",
        wrapperCustomClassName:
          "form-group col-12 col-md-4 float-left pl-md-0 clearfix",
        customClassName:
          errors.includes("medicalInsuranceNumber") && classNameError,
        errorMessage: _errors.includes("medicalInsuranceNumber")
          ? errorMessages["medicalInsuranceNumber"]
          : null,
        error: _errors.includes("medicalInsuranceNumber") && classNameError,
        visible:
          this.state.medicalInsuranceName.toLocaleLowerCase() !==
          "mpn - medicamentos de primer nivel",
      },
    ];

    const validateForm = () => {
      let errors = [];
      this.clearErrors();
      inputs.map((input) => {
        if (input.required && !this.state[input.state]) {
          errors.push(input.state);
        }
      });

      inputsIdentification.map((input) => {
        if (input.required && !this.state[input.state]) {
          errors.push(input.state);
        }
      });

      const inputsToValidate = !this.state.isIOSCOR
        ? inputsInsurance
        : inputsInsuranceIoscor;
      inputsToValidate.map((input) => {
        if (input.required && !this.state[input.state]) {
          errors.push(input.state);
        }
      });
      this.setState({
        errors,
      });

      return !errors.length;
    };

    const onSubmitPatientForm = () => {
      if (validateForm()) {
        this.setState({ sentRequest: true });

        const objData = {
          clinic_id: this.state.clinic.id,
          name: this.state.name,
          lastname: this.state.lastname,
          gender_id: this.state.gender.id,
          email: this.state.email,
          cell_phone: this.state.phoneNumber,
          birth_date: this.state.birthDate,
          identification_type_id: this.state.identificationTypeId,
          identification: this.state.identification,
          medical_insurance_id: this.state.medicalInsuranceId.id,
          medical_insurance_plan: this.state.medicalInsurancePlan,
          medical_insurance_number: this.state.medicalInsuranceNumber,
          medical_insurance_dni_titular: this.state.medicalInsuranceDniTitular,
          medical_insurance_selected: 1,
          comment: this.state.comment,
          profile_image: this.state.profileImage,
        };
        const itiFlag = document.querySelector(".iti__selected-flag");
        if (itiFlag) {
          const itiRegex = /\+\d+/gm;
          if (itiRegex.test(itiFlag.title)) {
            const prefix = itiFlag.title.match(itiRegex)[0];
            objData.prefix_cell_phone = prefix;
          }
        }

        this.api
          .postPatientByClinicId(objData)
          .then((res) => {
            // this.props.showMainModal(locales_es.successModal.title, locales_es.patientDataModal.sentDataSuccessfully);
            this.setState({
              success: true,
            });
          })
          .catch((err) => {
            this.props.showMainModal(
              locales_es.errorModal.title,
              this.helpers.getErrorMsg(err)
            );
            this.setState({ sentRequest: false });
          });
      } else {
        this.props.showMainModal(
          locales_es.errorModal.title,
          locales_es.errorModal.completeAllRequiredFormFields
        );
      }
    };

    const setShowWelcome = (bool) => {
      this.setState(
        {
          showWelcome: bool,
        },
        () => {
          if (bool === false) {
            this.api
              .getCountryById(this.state.clinic.country_id)
              .then((res) => {
                this.setState(
                  {
                    clinicCountryCode: res.data.code,
                  },
                  () => {
                    this.props.parseTelInputs(res.data.code);
                  }
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      );
    };

    const onSaveCrop = (image) => {
      this.setState({
        Cropping: false,
        profileImage: image,
      });
    };

    const onCropping = () => {
      this.setState({
        Cropping: true,
      });
    };

    const onStopCropping = () => {
      this.setState({
        Cropping: false,
      });
    };

    const {
      clinic,
      clinicLogoImage,
      showWelcome,
      codeIsValid,
      success,
      genderOptions,
      gender,
      profileImage,
    } = this.state;

    return (
      <>
        {}

        <div className="page-box w-100">
          <div className="app-container">
            <main className="main-content mb-0">
              <div className="app-loader">
                <i className="icofont-spinner-alt-4 rotate" />
              </div>

              {codeIsValid ? (
                showWelcome && (
                  <div className="container d-flex justify-content-center align-items-center flex-column text-center pb-5 mb-5">
                    <h4 className="mb-4 p-2 mt-5">
                      {clinic.name} Necesita Sus Datos
                    </h4>
                    <img
                      className="masthead-avatar mb-5"
                      width="50%"
                      style={{ maxWidth: "200px", borderRadius: "50%" }}
                      src={this.state.clinic.full_logo_image}
                      alt={`${clinic.name}`}
                    />
                    <p>
                      {clinic.name} solicita que complete sus datos para poder
                      empezar a enviarle sus recetas médicas de manera más ágil
                      y personalizada.
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowWelcome(false)}
                    >
                      Ingresar mis datos
                    </button>
                  </div>
                )
              ) : (
                <div className="container h-100 d-flex justify-content-center align-items-center flex-column text-center">
                  <h1 className="mb-5">Institución no encontrada</h1>
                  <h4>Por favor, póngase en contacto con la institución.</h4>
                </div>
              )}

              {success ? (
                <div className="container h-100 d-flex justify-content-center align-items-center flex-column text-center">
                  <img
                    className="masthead-avatar mb-1"
                    width="50%"
                    style={{ maxWidth: "200px", borderRadius: "50%" }}
                    src={SuccessIcon}
                  />
                  <h2 className="mb-4">Datos cargados correctamente</h2>
                  {/*<img className="masthead-avatar mb-5" width="50%" style={{maxWidth: '200px',borderRadius: '50%'}}
                         alt={`${this.state.name} ${this.state.lastname}`}/>*/}
                  <p>{`${this.state.name} ${this.state.lastname} tus datos fueron cargados correctamente.`}</p>
                </div>
              ) : codeIsValid && !showWelcome ? (
                <div className="main-content-wrap mt-5">
                  <header className="page-header">
                    <h1 className="page-title">{locales_es.patientData}</h1>
                  </header>

                  <div className="page-content">
                    <p>
                      Complete sus datos para sumarse a la cartilla de pacientes
                      del {this.state.clinic.name}
                    </p>
                    <hr />
                    <div className="card">
                      <ImgLoadAndCrop
                        addPatient={true}
                        avatarStyles={styles.avatarStyles}
                        containerStyles={styles.imgContainer}
                        image={profileImage}
                        inputLabel={"Imagen de perfil"}
                        imageId={"profileImage"}
                        imageState={"profileImage"}
                        inputWrapperCustomClassName="col-12 pl-md-0"
                        canvasCustomClassName="profile"
                        aspect={1}
                        saveCrop={onSaveCrop}
                        deleteCrop={() => console.log("deleteCrop")}
                        deletedImage={() => console.log("deleteImage")}
                        onCropping={onCropping}
                        onStopCropping={onStopCropping}
                      />
                      <Form
                        style={"mt-3 mb-3"}
                        inputs={inputs}
                        handleChange={this.handleChange}
                        handleReactSelectChange={this.handleReactSelectChange}
                        handleDateChange={this.handleDateChange}
                      />

                      <Form
                        style={"clearfix mt-3 mb-3"}
                        inputs={inputsIdentification}
                        handleChange={this.handleChange}
                        handleReactSelectChange={this.handleReactSelectChange}
                      />
                      <View style={styles.formRow}>
                        <View style={styles.flexGrow}>
                          <Text size={14} lineHeight={20} marginTop={24} bold>
                            Sexo <RequireIndicator />
                          </Text>
                          <View style={styles.genders}>
                            {genderOptions &&
                            Object.entries(genderOptions).length ? (
                              Object.entries(genderOptions).map(
                                ([key, value], index) => {
                                  const onPress = () => {
                                    this.setState({ gender: value });
                                  };
                                  return (
                                    <GenderItem
                                      {...value}
                                      testID={value.name}
                                      isChoose={value.id == gender.id}
                                      isLastItem={
                                        index ===
                                        Object.entries(genderOptions).length - 1
                                      }
                                      onPress={onPress}
                                      key={key}
                                      stylesOverride={{ width: 80, height: 80 }}
                                    />
                                  );
                                }
                              )
                            ) : (
                              <ActivityIndicator
                                style={CommonStyles.activityIndicator}
                              />
                            )}
                          </View>
                        </View>
                      </View>

                      <Form
                        style={"clearfix mt-3"}
                        inputs={
                          !this.state.isIOSCOR
                            ? inputsInsurance
                            : inputsInsuranceIoscor
                        }
                        handleChange={this.handleChange}
                        handleReactSelectChange={this.handleReactSelectChange}
                      />

                      <hr />
                    </div>

                    <div className="row">
                      <div className="clear flex-wrap justify-content-center justify-content-md-around text-center mt-2 col-6"></div>

                      <div className="clear flex-wrap justify-content-center justify-content-md-around text-center mt-2 col-12">
                        <textarea
                          onChange={(e) =>
                            this.setState({
                              comment: e.target.value,
                            })
                          }
                          className="form-control rounded"
                          placeholder={locales_es.observations}
                        >
                          {this.state.observations}
                        </textarea>
                      </div>
                    </div>

                    <div className="clear flex-wrap justify-content-center justify-content-md-around text-center mt-5 mb-5 pb-5">
                      <button
                        type="button"
                        onClick={onSubmitPatientForm}
                        className="btn btn-primary w-25"
                        disabled={this.state.sentRequest}
                      >
                        {locales_es.send}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const styles = StyleSheet.create({
  cardAction: {
    tintColor: "#00c1c1",
    color: "#00c1c1",
  },
  overlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  inputModalInsurance: {
    width: "100%",
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    padding: 5,
  },
  modalShowPracticesContainer: {
    backgroundColor: "white",
    width: "50%",
    height: "65%",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 15,
    paddingTop: 30,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  profileListIcon: {
    marginHorizontal: 5,
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: 16,
  },
  flexGrow: { flex: 1 },
  avatarStyles: {
    width: 150,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    marginRight: 20,
    zIndex: -1,
  },
  imgContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    overflow: "hidden",
    zIndex: 1000,
  },
  imgContainer: { minWidth: 380, width: "50%", marginTop: 0, zIndex: 1000 },
});

export default withRouter(ClinicPatientsData);
