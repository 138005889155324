import React, {memo, useCallback} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
  Image,
  ColorValue,
} from 'react-native';
import {Colors} from '../../../configs';
import Theme from '../../../style/Theme';
import {SOURCE_ICON} from '../../../images';
import Text from '../../../components/Text';

interface ButtonIconProps {
  icon?: any;
  text?: string;
  style?: ViewStyle;
  imgStyle?: ViewStyle;
  onPress?: () => void;
  disabled?: boolean;
  tintColor?: ColorValue | string;
  textStyle?: object;
  iconPosition?: 'right' | 'left';
}

const ButtonIcon = memo(
  ({icon, text, style,imgStyle, onPress, textStyle, iconPosition, ...props}: ButtonIconProps) => {
    const _onPress = useCallback(() => {
      onPress && onPress();
    }, [onPress]);

    const iconRender = () => {
      return <Image
          // source={{uri: "https://m.media-amazon.com/images/M/MV5BNjUzODQ5MDY5NV5BMl5BanBnXkFtZTgwOTc1NzcyMjE@._V1_.jpg"}}
          // source="https://m.media-amazon.com/images/M/MV5BNjUzODQ5MDY5NV5BMl5BanBnXkFtZTgwOTc1NzcyMjE@._V1_.jpg"
            source={SOURCE_ICON[`${icon}`]}
          style={[{
            marginHorizontal: 10,
            tintColor: props.tintColor,
          }, styles.image, imgStyle]}
            resizeMode="contain"
        />
    }

    return (
      <TouchableOpacity
        {...props}
        activeOpacity={0.54}
        style={[styles.container, style]}
        onPress={_onPress}
        disabled={props.disabled}
      >
        {iconPosition == 'left' ? iconRender() : null}
        {text && (
          <Text bold color="white" size={16} lineHeight={22} >
            <span style={textStyle || {}}>{text}</span>
          </Text>
        )}
        {(icon && !iconPosition) || iconPosition == 'right' ? iconRender() : null}
      </TouchableOpacity>
    );
  },
);

export default ButtonIcon;

const styles = StyleSheet.create({
  container: {
    height: 32,
    // width: 32,
    borderRadius: 8,
    justifyContent: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
    ...Theme.center,
  },
    image: {
        width: 50,
        height: 50,
        borderRadius: 12,
    }
});
