import React, {memo, useCallback, useEffect, useState} from 'react';
import {View, StyleSheet, Image} from 'react-native';
import Theme from '../../style/Theme';
import LinearColors from '../../components/LinearColors';
import {Colors, Constants, Routes} from '../../configs';
import ButtonText from '../../components/Buttons/ButtonText';
import Slider from '../../components/Slider';
import Text from '../../components/Text';
import {getBottomSpace} from 'react-native-iphone-x-helper';
import { useHistory } from "react-router-dom";
import CountryService from '../../modules/CountryService';
import {SOURCE_ICON} from '../../images';
import {EVENT_LOADED_COUNTRY, EVENT_SHOW_MODAL_INFO} from '../../constants/Events';
import imgOne from '../../images/walkthrough/01/img-1.png';
import imgTwo from '../../images/walkthrough/02/img-1.png';
import imgThree from '../../images/walkthrough/03/img-1.png';
import imgFour from '../../images/walkthrough/04/img-1.png';
import {EventRegister} from "../../modules/EventRegister";

const onboardingPages = [
  {
    id: 1,
    image: imgOne,
    desc: 'Envíe sus recetas de forma ágil y veloz',
  },
  {
    id: 2,
    image: imgTwo,
    desc: 'Recetas validadas que no son rechazadas al momento de la compra',
  },
  {
    id: 3,
    image: imgThree,
    desc: 'Vademecum actualizado y comprobación de interacciones medicamentosas',
  },
  {
    id: 4,
    image: imgFour,
    desc: 'Recetas frecuentes y datos de tus pacientes en tu bolsillo',
  },
];

const OnBoarding = memo((props) => {
  const history = useHistory();
  const [country, setCountry] = useState(false);
  const countryService = new CountryService();
  const onLogin = useCallback(() => {
    history.push(`/${Routes.Login}`);
  }, [history]);
  const onSignUp = useCallback(() => {
    history.push(`/${Routes.SignUp}`);
    // history.push`/${(Routes.BasicInformation}`);
    /*EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: 'Registro de nuevo médico',
      message: 'Para registrarse, descarga la aplicación en tu teléfono',
    })*/
  }, [history]);
  const onGetHere = useCallback(() => {
    history.push(`/${Routes.CountrySelector}`);
  }, [history]);

  const loadCountry = () => {
    countryService
      .getData()
      .then((res) => {
        setCountry(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadCountry();
  }, [history]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_LOADED_COUNTRY,
      data => {
        loadCountry();
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  return (
    <View testID="onBoarding" style={styles.container}>
      <LinearColors
        style={StyleSheet.absoluteFillObject}
        colors={[Colors.TealBlue, Colors.TurquoiseBlue]}>
          <Slider slides={onboardingPages}/>
        <ButtonText
          white
          title={'Iniciar Sesión'}
          style={styles.loginButton}
          titleColor={Colors.White}
          textProps={{bold: true}}
          onPress={onLogin}
          borderColor={Colors.White}
        />
        <ButtonText
          hilight
          title={'Registrarse'}
          style={styles.signUpButton}
          titleColor={Colors.TealBlue}
          textProps={{bold: true}}
          onPress={onSignUp}
          borderColor={Colors.TealBlue}
        />
      </LinearColors>
    </View>
  );
});

export default OnBoarding;

const styles = StyleSheet.create({
  container: {
    ...Theme.container,
  },
  loginButton: {
    width: (Constants.width - 88) / 2,
    height: 50,
    position: 'absolute',
    bottom: (Constants.height / 812) * 77 + getBottomSpace(),
    left: 32,
  },
  signUpButton: {
    width: (Constants.width - 88) / 2,
    height: 50,
    position: 'absolute',
    bottom: (Constants.height / 812) * 77 + getBottomSpace(),
    right: 32,
    backgroundColor: Colors.White,
  },
  changeApp: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 16 + getBottomSpace(),
    alignSelf: 'center',
  },
});
