import React from 'react';
import {View, StyleSheet, Image, ViewStyle} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import Layout from '../../components/Layout/Layout';
import {useTheme} from '../../configs/Theme';
import TouchableOpacityHOC from '../../components/TouchableOpacityHOC';
import {renderAge} from '../../modules/ViewHelpers';
import locales_es from '../../locales/es';
import avatarProfileIcon from '../../images/avatar/ic_account_normal1.png';
import phoneIcon from '../../images/ic_call.png';
import TagItem from '../WorkProfile/TagItem';
import Please from 'pleasejs';

interface UserInfoProps {
  user: any;
  style?: ViewStyle;
  dark?: boolean;
  hideClinicTag?: boolean;
  onPress?: () => void;
}

const UserInfo = (props: UserInfoProps) => {
  const {theme} = useTheme();

  return (
    <TouchableOpacityHOC onPress={props.onPress}>
      <Layout
        style={[
          styles.container,
          props.style,
          {
            borderColor: theme.lineColor,
            backgroundColor: props.dark ? theme.userInfo : theme.backgroundItem,
          },
        ]}>
        {/*<Text size={15} lineHeight={18} bold marginBottom={8}>
          {JSON.stringify(props)}
        </Text>*/}
        <Image
          source={
            props.user && props.user.full_profile_image
              ? {uri: props.user.full_profile_image}
              : avatarProfileIcon
          }
          style={{width: 64, height: 64, marginRight: 24}}
        />
        <View>
          <Text size={15} lineHeight={18} bold marginBottom={8}>
            {props.user.name} {props.user.lastname}
          </Text>
          <View style={{...Theme.flexRow, marginBottom: 8}}>
            {props.user && props.user.gender && props.user.gender.name && (
              <Text size={13} lineHeight={16} semibold marginRight={18}>
                {/*{locales_es.userType[props.user.user_type]}*/}
                {locales_es.gender}: {props.user.gender.name}
              </Text>
            )}
          </View>
          {props.user.birth_date && (
            <View style={{...Theme.flexRow, marginBottom: 8}}>
              <Text size={13} lineHeight={16}>
                {renderAge(props.user.birth_date, true)}
              </Text>
            </View>
          )}
          {props.user.prefix_cell_phone && props.user.cell_phone ? (
            <View style={{...Theme.flexRow}}>
              <Image
                source={phoneIcon}
                style={Theme.icons16}
              />
              <Text size={13} lineHeight={16} marginLeft={8}>
                {props.user.cell_phone
                  ? props.user.prefix_cell_phone + ' ' + props.user.cell_phone
                  : locales_es.hasNot}
              </Text>
            </View>
          ) : null}
          {props.user.clinic && props.user.clinic.name && !props.hideClinicTag ? (
            <View style={{flexWrap: 'wrap'}}>
              <TagItem
                style={{
                  backgroundColor: Please.make_color({
                    hue: props.user.clinic.id * 36, // arbitrary default number
                    saturation: 0.75,
                    value: 0.65,
                    base_color: '',
                    greyscale: false,
                    grayscale: false,
                    golden: false,
                    full_random: false,
                    colors_returned: 1,
                    format: 'hex',
                  })[0].toString(),
                }}
                title={props.user.clinic.name}
              />
            </View>
          ) : null}
        </View>
      </Layout>
    </TouchableOpacityHOC>
  );
};

export default UserInfo;

const styles = StyleSheet.create({
  container: {
    padding: 24,
    borderRadius: 16,
    ...Theme.shadow,
    ...Theme.flexRow,
  },
});
