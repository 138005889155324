import React, { useState, useEffect } from "react";
import { Alert, ActivityIndicator, StyleSheet } from "react-native";
import Theme from "../../style/Theme";
import { Colors, Routes } from "../../configs";
import { useTheme } from "../../configs/Theme";
import locales_es from "../../locales/es";
import Text from "../../components/Text";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import { CommonStyles } from "../../styles/CommonStyles";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import CountryService from "../../modules/CountryService";
import Footer from "../../components/footer";
import { useHistory } from "react-router-dom";
import "./styles.css";

export const CountrySelector = () => {
  const { theme } = useTheme();
  const api = new APIService();
  const helpers = new Helpers();
  const countryService = new CountryService();
  // const {navigate} = useNavigation();
  const history = useHistory();
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    history.replace(`/${Routes.OnBoarding}`);
    if (!countries) {
      api
        .getCountries()
        .then((res: any) => {
          setCountries(res.data);
        })
        .catch((err: any) => {
          Alert.alert(locales_es.errorModal.title, helpers.getErrorMsg(err));
        });
    }
  }, [countries]);

  const setCountryData = (country) => {
    countryService.setData(country);
    history.push(`/${Routes.OnBoarding}`);
  };

  return (
    <>
      <div
        testID="countrySelectorScreen"
        className="w-100 p-md-0 container-fluid"
        style={{ flex: 1, backgroundColor: theme.backgroundItem }}
      >
        <div className="row h-100">
          <div className="d-none d-md-inline-flex col-6">
            <div className="rcta-bg-logo" />
          </div>
          <div className="col-12 col-md-6 align-self-center text-center">
            <div className="text-center" style={styles.welcomeStyle}>
              {locales_es.country_selector.welcome}
            </div>
            <div className="text-center">
              <Text
                size={20}
                lineHeight={24}
                center
              >
                <span style={styles.subtitle}>{locales_es.chooseYourCountry}</span>
              </Text>
              {countries === null ? (
                <ActivityIndicator style={CommonStyles.activityIndicator} />
              ) : countries && countries.length ? (
                countries.map((country: any, index) => (
                  <ButtonIcon
                    key={index}
                    testID={country.name}
                    style={{
                      backgroundColor: Colors.Black68,
                      height: 55,
                      width: "100%",
                      maxWidth: 400,
                      borderRadius: 16,
                      margin: "auto",
                      marginTop: 24,
                      marginBottom: 24,
                      flexDirection: 'row-reverse',
                    }}
                    textStyle={{
                      fontSize: 18,
                      fontWeight: 600
                    }}
                    imgStyle={{
                      width: 32,
                    }}
                    onPress={() => setCountryData(country)}
                    icon={country.code.toLowerCase()}
                    text={country.name}
                  />
                ))
              ) : (
                <Text>
                  No se encontraron países disponibles. Contacte al
                  administrador
                </Text>
              )}
            </div>
            <Text size={20} lineHeight={24} center>
              <span style={styles.subtitle}>{locales_es.moreCountriesSoon}</span>
            </Text>
          </div>
          <Footer className={helpers.isMobile() ? "" : "app-footer-auth"} />
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    ...Theme.flexOne,
    backgroundColor: Colors.White,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: "600",
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: "400",
  },
  highlight: {
    fontWeight: "700",
  },
  formStyle: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bgStyle: {
    flex: 0.5,
    display: "flex",
  },
  welcomeStyle: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "4rem",
  },
  subtitle: {
    fontWeight: 600,
  },
});
